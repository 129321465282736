import { DozenSupabaseClient, Tables } from "../../database.types";

// League CRUD operations
const leagueService = {
  async getAll(supabase: DozenSupabaseClient): Promise<Tables<"leagues">[]> {
    const { data, error } = await supabase
      .from("leagues")
      .select("*")
      .order("name");

    if (error) {
      console.error("Error fetching leagues:", error);
      throw error;
    }

    return data || [];
  },

  async getById(
    supabase: DozenSupabaseClient,
    id: string
  ): Promise<Tables<"leagues"> | null> {
    const { data, error } = await supabase
      .from("leagues")
      .select("*")
      .eq("user_id", id)
      .single();

    if (error) {
      console.error(`Error fetching league with id ${id}:`, error);
      throw error;
    }

    return data;
  },

  async create(
    supabase: DozenSupabaseClient,
    league: Omit<Tables<"leagues">, "id" | "created_at" | "updated_at">
  ): Promise<Tables<"leagues">> {
    const { data, error } = await supabase
      .from("leagues")
      .insert(league)
      .select()
      .single();

    if (error) {
      console.error("Error creating league:", error);
      throw error;
    }

    return data;
  },

  async update(
    supabase: DozenSupabaseClient,
    id: string,
    updates: Partial<
      Omit<Tables<"leagues">, "id" | "created_at" | "updated_at">
    >
  ): Promise<Tables<"leagues">> {
    const { data, error } = await supabase
      .from("leagues")
      .update(updates)
      .eq("id", id)
      .select()
      .single();

    if (error) {
      console.error(`Error updating league with id ${id}:`, error);
      throw error;
    }

    return data;
  },

  async delete(supabase: DozenSupabaseClient, id: string): Promise<void> {
    const { error } = await supabase.from("leagues").delete().eq("id", id);

    if (error) {
      console.error(`Error deleting league with id ${id}:`, error);
      throw error;
    }
  },
};

export default leagueService;