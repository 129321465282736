import { v4 } from "uuid";
import { Tables, TablesInsert } from "../../database.types";
import { App } from "../types/types";

// validate board before and after every single POST/GET on a ready-to-play or mid-game or post-game board
export const activeBoardIsValid = (board?: App.Board): boolean => {
  const errors: string[] = [];

  if (!board) return false;

  // make sure 3 or less lifelines were used per team (one of each)

  // make sure no completed rounds come after un-completed rounds

  // make sure that only one round has a status of 1 or 2. and all 3s come before that one and all 0s after

  return true;
};

export const boardAndGameConfigIsComplete = (
  board?: App.Board,
  game?: TablesInsert<"games">
): string[] => {
  let errs: string[] = [];

  if (!board || !boardIsValid(board)) {
    errs.push("Invalid Board");
  }
  if (!game) {
    errs.push("No Game Found");
  }

  if (!game?.team_one_id) {
    errs.push("No team 1 selected");
  }
  if (!game?.team_two_id) {
    errs.push("No team 2 selected");
  }

  // make sure there are niche categories
  if (!game?.team_1_niche_category) {
    errs.push("No niche for team 1");
  }
  if (!game?.team_2_niche_category) {
    errs.push("No niche for team 2");
  }

  if (!board?.rounds.every((r, idx) => isRoundConfigComplete(r, idx))) {
    errs.push("Not all rounds filled out");
  }

  // classic - make sure we have 25+ options in our answer list
  if (board?.bonus_round.type === "Classic (List)") {
    if ((board.bonus_round.classic_bonus_round?.answers ?? []).length < 25) {
      errs.push("Not enough classic bonus round answers");
    }
    if (!board.bonus_round.classic_bonus_round?.answers.every((a) => a)) {
      errs.push("Not all bonus round answers are filled in");
    }
  } else {
    if (
      (board?.bonus_round.closest_guess_bonus_round?.questions ?? []).length < 6
    ) {
      errs.push("Not enough closest guess bonus round questions (min 6)");
    }
    if (
      !board?.bonus_round.closest_guess_bonus_round?.questions.every(
        (q) => q.question && q.answer
      )
    ) {
      errs.push(
        "Not all closest guess bonus round questions and answers are filled in"
      );
    }
  }
  return errs;
};

export const boardIsValid = (board?: App.Board): boolean => {
  if (!board) return false;
  // make sure there are 12 rounds
  if (board.rounds.length !== 12) {
    return false;
  }

  // make sure the bonus round type matches the bonus round type in the bonus round object
  if (board.bonus_round.type === "Classic (List)") {
    if (!board.bonus_round.classic_bonus_round) {
      return false;
    }
  } else {
    if (!board.bonus_round.closest_guess_bonus_round) {
      return false;
    }
  }
  return true;
};

const emptyQuestion: App.Question = {
  question: "",
  answer: "",
  attachment_ids: [],
  correct_team_id: null,
};

const get12EmptyRounds = (): App.Round[] =>
  Array.from({ length: 12 }, () => ({
    question_1: emptyQuestion,
    question_2: emptyQuestion,
    team_1_lifelines_used: [],
    team_2_lifelines_used: [],
    category: "",
    status: 0,
  }));

const emptyBonusRound: App.BonusRound = {
  type: "Classic (List)",
  classic_bonus_round: {
    question: "",
    answers: [],
    team1Answers: [],
    team2Answers: [],
  },
  closest_guess_bonus_round: null,
  winning_team_id: null,
};

export const initialCurrentRoundState: App.CurrentRoundData = {
  currentRoundIdx: null,
  question: 1,
  showQuestion: false,
  showAnswer: false,
};

export const getEmptyGameBoard = (): App.Board => {
  return {
    rounds: get12EmptyRounds(),
    bonus_round: emptyBonusRound,
    currentRound: initialCurrentRoundState,
    overtime: null,
  };
};

export const getEmptyGame = (
  league_id: string,
  game_name: string,
  board_id: string
): TablesInsert<"games"> => {
  return {
    id: v4(),
    name: game_name,
    league_id: league_id,
    created_at: new Date().toISOString(),
    team_2_niche_category: "",
    team_1_niche_category: "",
    // team_one_id: "",
    // team_two_id: "",
    bonus_round_type: "Classic (List)",
    config_complete: false,
    played: false,
    board_id: board_id,
    updated_at: new Date().toISOString(),
  };
};

export const isRoundConfigComplete = (
  round: App.Round,
  roundIdx: number
): boolean => {
  return !!(
    (round.category || roundIdx === 9) && // niche category doesn't need a category title
    round.question_1.question &&
    round.question_1.answer &&
    round.question_2.question &&
    round.question_2.answer
  );
};

export const isDoubleSipActive = (
  currentRound: App.Round,
  teamOne: boolean
) => {
  return teamOne
    ? currentRound.team_1_lifelines_used.includes("Double Sip")
    : currentRound.team_2_lifelines_used.includes("Double Sip");
};

export const isDoubleUpActive = (currentRound: App.Round, teamOne: boolean) => {
  return teamOne
    ? currentRound.team_1_lifelines_used.includes("Double up")
    : currentRound.team_2_lifelines_used.includes("Double up");
};

export const isCallAFriendActive = (
  currentRound: App.Round,
  teamOne: boolean
) => {
  return teamOne
    ? currentRound.team_1_lifelines_used.includes("Phone a Friend")
    : currentRound.team_2_lifelines_used.includes("Phone a Friend");
};

export const isLifelineUsed = (
  board: App.Board,
  teamOne: boolean,
  lifeline: App.Lifeline
) => {
  if (teamOne) {
    return board.rounds.some((round) =>
      round.team_1_lifelines_used.includes(lifeline)
    );
  } else {
    return board.rounds.some((round) =>
      round.team_2_lifelines_used.includes(lifeline)
    );
  }
};

export const getTeamScores = (
  board: App.Board,
  team1Id: string,
  team2Id: string
) => {
  let team1Score: number = 0;
  let team2Score: number = 0;

  // get all standard round scores - TODO - include niche calculations
  board.rounds.forEach((r) => {
    if (r.question_1.correct_team_id === team1Id) {
      team1Score += r.team_1_lifelines_used.includes("Double up") ? 2 : 1;
    }
    // would have to be a steal, so no double up
    if (r.question_1.correct_team_id === team2Id) {
      team2Score += 1;
    }

    // would have to be a steal, so no double up
    if (r.question_2.correct_team_id === team1Id) {
      team1Score += 1;
    }
    if (r.question_2.correct_team_id === team2Id) {
      team2Score += r.team_1_lifelines_used.includes("Double up") ? 2 : 1;
    }
  });

  const bonusRoundPoints = getBonusRoundPoints(
    board.bonus_round,
    team1Id,
    team2Id
  );
  team1Score += bonusRoundPoints.team1;
  team2Score += bonusRoundPoints.team2;

  const overtimePoints = board.overtime
    ? getBonusRoundPoints(board.overtime, team1Id, team2Id)
    : { team1: 0, team2: 0 };
  team1Score += overtimePoints.team1;
  team2Score += overtimePoints.team2;

  return {
    team1: team1Score,
    team2: team2Score,
  };
};

export const getClassicBonusRoundPoints = (
  classicBonusRound: App.ClassicBonusRoundBoard,
  team1Id: string,
  team2Id: string
): { team1: number; team2: number } => {
  if (
    !(
      classicBonusRound.team1Answers.length >= 5 ||
      classicBonusRound.team2Answers.length >= 5
    )
  ) {
    // not enough completed rounds
    return { team1: 0, team2: 0 };
  }

  let team1BonusRoundAnswers = 0;
  let team2BonusRoundAnswers = 0;
  classicBonusRound.answers.forEach((a) => {
    if (!a.team_correct_id) {
      return;
    }
    if (a.team_correct_id === team1Id) {
      team1BonusRoundAnswers += 1;
      return;
    }
    if (a.team_correct_id === team2Id) {
      team2BonusRoundAnswers += 1;
    }
  });

  if (team1BonusRoundAnswers > team2BonusRoundAnswers) {
    return { team1: 2, team2: 0 };
  }
  if (team2BonusRoundAnswers > team1BonusRoundAnswers) {
    return { team1: 0, team2: 2 };
  }
  return { team1: 0, team2: 0 };
};

export const getClosestGuessBonusRoundPoints = (
  closestGuessBonusRound: App.ClosestGuessBonusRoundBoard
): { team1: number; team2: number } => {
  if (
    closestGuessBonusRound.questions.filter(
      (q) => q.answer && q.team_1_answer && q.team_2_answer
    ).length < 3
  ) {
    // not enough rounds completed to award points
    return { team1: 0, team2: 0 };
  }
  let team1Wins = 0;
  let team2Wins = 0;
  closestGuessBonusRound.questions.forEach((q) => {
    if (!q.answer || !q.team_1_answer || !q.team_2_answer) {
      return;
    }
    const team1Diff = q.answer - q.team_1_answer;
    const team2Diff = q.answer - q.team_2_answer;
    if (team1Diff < team2Diff) {
      team1Wins++;
    } else if (team2Diff < team1Diff) {
      team2Wins++;
    }
  });
  console.log(team1Wins);
  console.log(team2Wins);
  if (team1Wins > 2 && team1Wins > team2Wins) {
    return { team1: 2, team2: 0 };
  }
  if (team2Wins > 2 && team2Wins > team1Wins) {
    return { team1: 0, team2: 2 };
  }
  return { team1: 0, team2: 0 };
};

export const getBonusRoundPoints = (
  bonusRound: App.BonusRound,
  team1Id: string,
  team2Id: string
): { team1: number; team2: number } => {
  let team1Score: number = 0;
  let team2Score: number = 0;

  if (bonusRound.type === "Classic (List)" && bonusRound.classic_bonus_round) {
    const classicBonusRoundScores = getClassicBonusRoundPoints(
      bonusRound.classic_bonus_round,
      team1Id,
      team2Id
    );
    team1Score += classicBonusRoundScores.team1;
    team1Score += classicBonusRoundScores.team2;
  } else if (
    bonusRound.type === "Closest Guess Battle" &&
    bonusRound.closest_guess_bonus_round
  ) {
    const closestGuessScores = getClosestGuessBonusRoundPoints(
      bonusRound.closest_guess_bonus_round
    );
    team1Score += closestGuessScores.team1;
    team2Score += closestGuessScores.team2;
  }

  return { team1: team1Score, team2: team2Score };
};

export const getRoundTitleText = (
  currentRoundIdx: number | "bonus" | "overtime" | null,
  category?: string
) => {
  if (currentRoundIdx === "bonus") {
    return "Bonus Round";
  }
  if (currentRoundIdx === "overtime") {
    return "Overtime";
  }
  if (currentRoundIdx === null) {
    return "Game not started";
  }
  return `Round ${currentRoundIdx + 1}: ${category ?? ""}`;
};
