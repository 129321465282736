import { useEffect, useMemo, useState } from "react";
import { Box, Button, Typography, CircularProgress } from "@mui/material";
import { isNumber } from "lodash";
import { App } from "../../types/types";
import { gameService } from "../../data/gameService";
import { useSupabase } from "../../auth/useSupabase";
import { Tables } from "../../../database.types";
import { useParams } from "react-router-dom";
import {
  activeBoardIsValid,
  initialCurrentRoundState,
} from "../../utils/utils";
import StandardRound from "./StandardRound";
import ClassicBonusRoundAdmin from "./ClassicBonusRoundAdmin";
import { teamService } from "../../data/teamService";
import ClosestGuessBonusRoundAdmin from "./ClosestGuessBonusRoundAdmin";

export const ControlCenterPage = () => {
  const { gameId } = useParams<{ gameId: string }>();

  const { supabase } = useSupabase();

  const [loading, setLoading] = useState<boolean>(true);
  const [game, setGame] = useState<Tables<"games"> | undefined>();
  const [board, setBoard] = useState<App.Board | null>(null);
  const [teamNames, setTeamNames] = useState<{
    team1: string;
    team2: string;
  } | null>(null);

  useEffect(() => {
    fetchGameAndBoard();
  }, [gameId]);

  const fetchGameAndBoard = async () => {
    try {
      if (gameId) {
        setLoading(true);
        const gameRes = await gameService.getById(supabase, gameId);
        if (!gameRes) {
          throw new Error("Game not found");
        }
        const team1Res = await teamService.getById(
          supabase,
          gameRes.team_one_id ?? ""
        );
        const team2Res = await teamService.getById(
          supabase,
          gameRes.team_two_id ?? ""
        );
        const boardRes = await gameService.getGameBoard(
          supabase,
          gameRes.board_id
        );
        if (
          boardRes?.board &&
          activeBoardIsValid(boardRes?.board as unknown as App.Board)
        ) {
          const cleanedBoard = { ...(boardRes?.board as unknown as App.Board) };
          setGame(gameRes);
          setBoard(cleanedBoard);
          setTeamNames(
            team1Res && team2Res
              ? { team1: team1Res.name, team2: team2Res.name }
              : null
          );
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const ControlCenterMemo = useMemo(() => {
    if (game && board && teamNames) {
      return (
        <ControlCenter
          initialGame={game}
          initialGameBoard={board}
          teamNames={teamNames}
        />
      );
    }
    return null;
  }, [game, board, teamNames]);

  if (loading) {
    return (
      <Box height="100%" width="100%">
        <CircularProgress />
      </Box>
    );
  }

  if (!game || !board) {
    return (
      <Box>
        <Typography variant="h3">
          Game not found, please refresh the page
        </Typography>
      </Box>
    );
  }

  return ControlCenterMemo;
};

const ControlCenter = (props: {
  initialGame: Tables<"games">;
  initialGameBoard: App.Board;
  teamNames: {
    team1: string;
    team2: string;
  } | null;
}) => {
  const { teamNames } = props;
  const { supabase } = useSupabase();

  const [game, setGame] = useState<Tables<"games">>(props.initialGame);
  const [gameBoard, setGameBoard] = useState<App.Board>(props.initialGameBoard);

  useEffect(() => {
    // add validation
    gameService.upsertGameBoard(
      supabase,
      game.board_id,
      gameBoard.currentRound // temporary ternary to make sure we always are sending a current state
        ? gameBoard
        : { ...gameBoard, currentRound: initialCurrentRoundState }
    );
  }, [gameBoard]);

  if (!game.team_one_id || !game.team_two_id) {
    return (
      <Box>
        <Typography>Missing team {!game.team_one_id ? 1 : 2} id.</Typography>
      </Box>
    );
  }

  if (isNumber(gameBoard.currentRound.currentRoundIdx)) {
    return (
      <StandardRound
        board={gameBoard}
        onChangeBoard={(board) => setGameBoard(board)}
        teamOneId={game.team_one_id}
        teamTwoId={game.team_two_id}
        teamOneName={props.teamNames?.team1 ?? "Team 1"}
        teamTwoName={props.teamNames?.team2 ?? "Team 2"}
        nicheCategory1={game.team_1_niche_category ?? ""}
        nicheCategory2={game.team_2_niche_category ?? ""}
        gameId={game.id}
        declareWinner={async (winningTeamId: string | null) => {
          setGame({ ...game, winning_team_id: winningTeamId });
          gameService.update(supabase, game.id, {
            ...game,
            winning_team_id: winningTeamId,
          });
        }}
        winningTeamId={game.winning_team_id}
      />
    );
  }

  if (gameBoard.currentRound.currentRoundIdx === "bonus") {
    if (
      gameBoard.bonus_round.type === "Classic (List)" &&
      gameBoard.bonus_round.classic_bonus_round
    ) {
      return (
        <ClassicBonusRoundAdmin
          bonusRound={gameBoard.bonus_round.classic_bonus_round}
          currentRoundIdx={gameBoard.currentRound.currentRoundIdx}
          onBonusRoundChange={(board) =>
            setGameBoard({
              ...gameBoard,
              bonus_round: {
                ...gameBoard.bonus_round,
                classic_bonus_round: board,
              },
            })
          }
          setCurrentRoundIdx={(n) => {
            const updatedBoard = { ...gameBoard };
            updatedBoard.currentRound.currentRoundIdx = n;
            updatedBoard.currentRound.showAnswer = false;
            updatedBoard.currentRound.showQuestion = false;
            updatedBoard.currentRound.question = 1;
            setGameBoard(updatedBoard);
          }}
          team1Id={game.team_one_id}
          team2Id={game.team_two_id}
        />
      );
    }
    if (
      gameBoard.bonus_round?.type === "Closest Guess Battle" &&
      gameBoard.bonus_round.closest_guess_bonus_round
    ) {
      return (
        <ClosestGuessBonusRoundAdmin
          bonusRound={gameBoard.bonus_round.closest_guess_bonus_round}
          currentRoundIdx={gameBoard.currentRound.currentRoundIdx}
          onBonusRoundChange={(board) =>
            setGameBoard({
              ...gameBoard,
              bonus_round: {
                ...gameBoard.bonus_round,
                closest_guess_bonus_round: board,
              },
            })
          }
          setCurrentRoundIdx={(n) => {
            const updatedBoard = { ...gameBoard };
            updatedBoard.currentRound.currentRoundIdx = n;
            updatedBoard.currentRound.showAnswer = false;
            updatedBoard.currentRound.showQuestion = false;
            updatedBoard.currentRound.question = 1;
            setGameBoard(updatedBoard);
          }}
          team1Name={teamNames?.team1 ?? "Team 1"}
          team2Name={teamNames?.team2 ?? "Team 2"}
        />
      );
    }
  }

  if (gameBoard.currentRound.currentRoundIdx === "overtime") {
    if (
      gameBoard.overtime?.type === "Classic (List)" &&
      gameBoard.overtime.classic_bonus_round
    ) {
      return (
        <ClassicBonusRoundAdmin
          bonusRound={gameBoard.overtime.classic_bonus_round}
          currentRoundIdx={gameBoard.currentRound.currentRoundIdx}
          onBonusRoundChange={(board) =>
            setGameBoard({
              ...gameBoard,
              overtime: gameBoard.overtime
                ? {
                    ...gameBoard.overtime,
                    classic_bonus_round: board,
                  }
                : null,
            })
          }
          setCurrentRoundIdx={(n) => {
            const updatedBoard = { ...gameBoard };
            updatedBoard.currentRound.currentRoundIdx = n;
            updatedBoard.currentRound.showAnswer = false;
            updatedBoard.currentRound.showQuestion = false;
            updatedBoard.currentRound.question = 1;
            setGameBoard(updatedBoard);
          }}
          team1Id={game.team_one_id}
          team2Id={game.team_two_id}
        />
      );
    }

    if (
      gameBoard.overtime?.type === "Closest Guess Battle" &&
      gameBoard.overtime.closest_guess_bonus_round
    ) {
      return (
        <ClosestGuessBonusRoundAdmin
          bonusRound={gameBoard.overtime.closest_guess_bonus_round}
          currentRoundIdx={gameBoard.currentRound.currentRoundIdx}
          onBonusRoundChange={(board) =>
            setGameBoard({
              ...gameBoard,
              overtime: gameBoard.overtime
                ? {
                    ...gameBoard.overtime,
                    closest_guess_bonus_round: board,
                  }
                : null,
            })
          }
          setCurrentRoundIdx={(n) => {
            const updatedBoard = { ...gameBoard };
            updatedBoard.currentRound.currentRoundIdx = n;
            updatedBoard.currentRound.showAnswer = false;
            updatedBoard.currentRound.showQuestion = false;
            updatedBoard.currentRound.question = 1;
            setGameBoard(updatedBoard);
          }}
          team1Name={teamNames?.team1 ?? "Team 1"}
          team2Name={teamNames?.team2 ?? "Team 2"}
        />
      );
    }
  }

  return (
    <Button
      onClick={() => {
        const updatedGame = { ...gameBoard };
        updatedGame.currentRound = {
          currentRoundIdx: 0,
          showAnswer: false,
          showQuestion: false,
          question: 1,
        };
        setGameBoard(updatedGame);
      }}
    >
      Start Game
    </Button>
  );
};

export default ControlCenter;
