import {
  Box,
  CircularProgress,
  Typography,
  Button,
  Grid,
  createTheme,
  ThemeProvider,
  IconButton,
} from "@mui/material";
import styled from "@mui/system/styled";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Tables } from "../../database.types";
import { App } from "../types/types";
import {
  activeBoardIsValid,
  getRoundTitleText,
  getTeamScores,
  isCallAFriendActive,
  isDoubleSipActive,
  isDoubleUpActive,
  isLifelineUsed,
} from "../utils/utils";
import { gameService } from "../data/gameService";
import { Phone, SportsBar, LooksTwoRounded } from "@mui/icons-material";
import { useSupabase } from "../auth/useSupabase";
import { teamService } from "../data/teamService";
import { isNumber } from "lodash";
import StandardQuestionDisplay from "./StandardQuestionDisplay";
import ClassicBonusRoundPlayer from "./ClassicBonusRoundPlayer";
import useScreenSize from "../hooks/useScreenSize";

const theme = createTheme();

theme.typography.h3 = {
  fontSize: "1.2rem",
  "@media (min-width:600px)": {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2rem",
  },
};

theme.typography.h1 = {
  fontSize: "2rem",
  fontWeight: 500,
  "@media (min-width:600px)": {
    fontSize: "2.5rem",
  },
};

theme.typography.h2 = {
  fontSize: "1.6rem",
  fontWeight: 500,
  "@media (min-width:600px)": {
    fontSize: "2rem",
  },
};

theme.typography.h3 = {
  fontSize: "1.2rem",
  "@media (min-width:600px)": {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2rem",
  },
};

theme.typography.body1 = {
  fontSize: "1rem",
  fontWeight: 400,
  "@media (min-width:600px)": {
    fontSize: "1.1rem",
  },
};

theme.typography.body2 = {
  fontSize: "0.875rem",
  fontWeight: 400,
  "@media (min-width:600px)": {
    fontSize: "0.95rem",
  },
};

theme.typography.caption = {
  fontSize: "0.75rem",
  fontWeight: 400,
  "@media (min-width:600px)": {
    fontSize: "0.8rem",
  },
};

// Custom styled components for retro look
export const RetroContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "16px",
  maxHeight: "calc(100vh - 32px)",
  height: "calc(100vh - 32px)",
  backgroundColor: "#121212",
  justifyContent: "space-between",
  backgroundImage: "radial-gradient(#3a3a3a 1px, transparent 0)",
  backgroundSize: "20px 20px",
  color: "#f5f5f5",
  fontFamily: '"Press Start 2P", "Roboto Mono", monospace',
  overflow: "auto",
}));

const RetroHeader = styled(Box)(({ theme }) => ({
  backgroundColor: "#1a237e",
  borderBottom: "4px solid #ffab00",
  padding: theme.spacing(2),
  marginBottom: theme.spacing(4),
  borderRadius: "8px",
  boxShadow:
    "0 4px 0 rgba(0, 0, 0, 0.2), inset 0 0 10px rgba(26, 35, 126, 0.5)",
}));

const RetroScoreBoard = styled(Box)(({ theme }) => ({
  backgroundColor: "#2a2a2a",
  width: "100%",
  borderRadius: "8px",
  padding: theme.spacing(1),
  border: "3px solid #ffab00",
  boxShadow: "0 6px 0 rgba(0, 0, 0, 0.3), inset 0 0 8px rgba(255, 171, 0, 0.1)",
}));

const TeamLogo = styled(Box)(({ theme }) => ({
  width: "120px",
  height: "120px",
  borderRadius: "60px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 auto",
  border: "3px solid #ffab00",
  boxShadow: "0 4px 0 rgba(0, 0, 0, 0.2)",
  backgroundColor: "#1a237e",
  overflow: "hidden",
}));

const ScoreDisplay = styled(Typography)(({ theme }) => ({
  backgroundColor: "#000",
  color: "#00e676",
  padding: "4px",
  borderRadius: "4px",
  textAlign: "center",
  variants: "h3",
  border: "2px solid #00e676",
  fontFamily: '"Digital", "Roboto Mono", monospace',
  margin: theme.spacing(1, 0),
  textShadow: "0 0 10px rgba(0, 230, 118, 0.7)",
}));

const RetroLogo = styled(Typography)(({ theme }) => ({
  color: "#ffab00",
  textAlign: "center",
  marginBottom: theme.spacing(1),
  textShadow: "0 0 10px rgba(255, 171, 0, 0.5)",
  letterSpacing: "2px",
  whiteSpace: "pre-line",
}));

const Player = () => {
  const { gameId } = useParams<{ gameId: string }>();

  const { supabase } = useSupabase();

  const [loading, setLoading] = useState<boolean>(true);

  const [game, setGame] = useState<Tables<"games"> | null>(null);
  const [board, setBoard] = useState<App.Board | null>(null);
  const [team1, setTeam1] = useState<Tables<"teams"> | null>(null);
  const [team2, setTeam2] = useState<Tables<"teams"> | null>(null);

  const { isSmall } = useScreenSize();

  const navigate = useNavigate();

  useEffect(() => {
    fetchGame();
  }, [gameId]);

  useEffect(() => {
    if (game?.board_id) {
      setupBoardSubscription();
    } else {
    }
  }, [game?.board_id]);

  const setupBoardSubscription = () => {
    if (game) {
      // Create a unique channel name with timestamp to avoid conflicts on reconnection
      // Add a random component to further ensure uniqueness
      const randomId = Math.random().toString(36).substring(2, 10);
      const channelName = `row-changes:boards:id=eq.${
        game.board_id
      }:${Date.now()}-${randomId}`;

      const channel = supabase
        .channel(channelName)
        .on(
          "postgres_changes",
          {
            event: "*",
            schema: "public",
            filter: `id=eq.${game.board_id}`,
          },
          (payload) => {
            console.log(payload);
            if (payload.eventType === "DELETE") {
              setBoard(null);
            } else {
              console.log((payload as any).data?.record?.board);
              setBoard((payload.new.board as App.Board) ?? null);
            }
          }
        )
        .subscribe(async (subscriptionStatus) => {
          console.log("Subscription status:", subscriptionStatus);

          /* if (unmountedRef.current) return; */

          if (subscriptionStatus === "SUBSCRIBED") {
            /* updateStatus("connected"); */
            // Refetch data to ensure we have the latest
            /* await fetchData(); */
          } else if (subscriptionStatus === "CHANNEL_ERROR") {
            /* updateStatus("error");
          handleReconnect(); */
          } else if (subscriptionStatus === "TIMED_OUT") {
            /* updateStatus("timeout");
          handleReconnect(); */
          } else if (subscriptionStatus === "CLOSED") {
            /* updateStatus("disconnected"); */
          }
        });
    }
  };

  const fetchGame = async () => {
    try {
      if (gameId) {
        setLoading(true);
        const gameRes = await gameService.getById(supabase, gameId);
        if (gameRes && gameRes.team_one_id && gameRes.team_two_id) {
          const team1Res = await teamService.getById(
            supabase,
            gameRes.team_one_id
          );
          const team2Res = await teamService.getById(
            supabase,
            gameRes.team_two_id
          );
          const initialBoard = await gameService.getGameBoard(
            supabase,
            gameRes.board_id
          );
          setTeam1(team1Res);
          setTeam2(team2Res);
          setGame(gameRes);
          if (
            initialBoard?.board &&
            activeBoardIsValid(initialBoard.board as unknown as App.Board)
          ) {
            setBoard(initialBoard.board as unknown as App.Board);
          } else {
            setBoard(null);
          }
        } else {
          setTeam1(null);
          setTeam2(null);
          setGame(null);
          // set error - game not found
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        flex={1}
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!board) {
    return (
      <Box>
        <Typography>
          Board not found. check link and refresh the page
        </Typography>
        <Button onClick={() => navigate("/admin")}>Or Go Home</Button>
      </Box>
    );
  }

  if (!game || !team1 || !team2) {
    return (
      <Box>
        <Typography>Game not found. check link and refresh the page</Typography>
      </Box>
    );
  }

  if (!board.currentRound) {
    return (
      <Box>
        <Typography>Current Round Obj not here. Hmm</Typography>
      </Box>
    );
  }

  const isStandardRound = isNumber(board.currentRound?.currentRoundIdx);

  const teamScores: { team1: number; team2: number } = getTeamScores(
    board,
    team1.id,
    team2.id
  );

  const team1LifelineState = (() => {
    return {
      phoneAFriend: {
        isActive:
          isNumber(board.currentRound.currentRoundIdx) &&
          isCallAFriendActive(
            board.rounds[board.currentRound.currentRoundIdx],
            true
          ),
        isUsed: isLifelineUsed(board, true, "Phone a Friend"),
      },
      doubleSip: {
        isActive:
          isNumber(board.currentRound.currentRoundIdx) &&
          isDoubleSipActive(
            board.rounds[board.currentRound.currentRoundIdx],
            true
          ),
        isUsed: isLifelineUsed(board, true, "Double Sip"),
      },
      doubleUp: {
        isActive:
          isNumber(board.currentRound.currentRoundIdx) &&
          isDoubleUpActive(
            board.rounds[board.currentRound.currentRoundIdx],
            true
          ),
        isUsed: isLifelineUsed(board, true, "Double up"),
      },
    };
  })();
  const team2LifelineState = (() => {
    return {
      phoneAFriend: {
        isActive:
          isNumber(board.currentRound.currentRoundIdx) &&
          isCallAFriendActive(
            board.rounds[board.currentRound.currentRoundIdx],
            false
          ),
        isUsed: isLifelineUsed(board, false, "Phone a Friend"),
      },
      doubleSip: {
        isActive:
          isNumber(board.currentRound.currentRoundIdx) &&
          isDoubleSipActive(
            board.rounds[board.currentRound.currentRoundIdx],
            false
          ),
        isUsed: isLifelineUsed(board, false, "Double Sip"),
      },
      doubleUp: {
        isActive:
          isNumber(board.currentRound.currentRoundIdx) &&
          isDoubleUpActive(
            board.rounds[board.currentRound.currentRoundIdx],
            false
          ),
        isUsed: isLifelineUsed(board, false, "Double up"),
      },
    };
  })();

  const getCurrentStandardQuestion = () => {
    if (!isNumber(board.currentRound.currentRoundIdx)) {
      return null;
    }

    const questionObj: App.Question =
      board.currentRound.question === 1
        ? board.rounds[board.currentRound.currentRoundIdx].question_1
        : board.rounds[board.currentRound.currentRoundIdx].question_2;

    return questionObj;
  };

  // page body means question (including answer), bonus round display, or any placeholder panel
  const renderPageBody = () => {
    console.log(board);
    if (isNumber(board.currentRound.currentRoundIdx)) {
      const questionObj = getCurrentStandardQuestion();
      if (questionObj) {
        return (
          <StandardQuestionDisplay
            question={questionObj.question}
            answer={questionObj.answer}
            attachmentIds={questionObj.attachment_ids}
            showAnswer={board.currentRound.showAnswer}
            showQuestion={board.currentRound.showQuestion}
          />
        );
      }
      return <Typography variant="h3">Error</Typography>;
    }
    if (
      board.currentRound.currentRoundIdx === "bonus" &&
      board.bonus_round.classic_bonus_round
    ) {
      return (
        <ClassicBonusRoundPlayer
          bonusRound={board.bonus_round.classic_bonus_round}
        />
      );
    }

    return null;
  };

  console.log(
    isNumber(board.currentRound.currentRoundIdx)
      ? board.rounds[board.currentRound.currentRoundIdx].category
      : undefined
  );

  const category = (() => {
    if (board.currentRound.currentRoundIdx === 9) {
      return board.currentRound.question === 1
        ? game.team_1_niche_category
        : game.team_2_niche_category;
    }
    return isNumber(board.currentRound.currentRoundIdx)
      ? board.rounds[board.currentRound.currentRoundIdx].category
      : undefined;
  })();

  const roundTitleText = getRoundTitleText(
    board.currentRound.currentRoundIdx,
    category ?? undefined
  );

  return (
    <RetroContainer>
      <Box
        display="flex"
        width="100%"
        flexDirection="column"
        gap="12px"
        alignItems="center"
      >
        <RetroHeader width="90%">
          <Typography
            variant="h3"
            align="center"
            sx={{
              fontWeight: "bold",
              color: "#ffab00",
              textShadow:
                "3px 3px 0px rgba(0,0,0,0.5), 0 0 10px rgba(255, 171, 0, 0.3)",
              letterSpacing: "4px",
            }}
          >
            The Dozen Trivia
          </Typography>
        </RetroHeader>
        <Typography variant="h2">{roundTitleText}</Typography>
      </Box>

      {/* Page Body */}
      {renderPageBody()}

      {/* Scoreboard */}
      <Grid container width="100%" spacing={4} alignSelf="baseline">
        {/* Team 1 */}
        <RetroScoreBoard>
          <Box display="flex" flex={1} width="100%" flexWrap="nowrap">
            <Box display="flex" flex={1} flexGrow={1}>
              <Box
                sx={{
                  textAlign: "center",
                  border: "2px dashed #ffab00",
                  borderRadius: "8px",
                  padding: 2,
                  backgroundColor: "rgba(0,0,0,0.2)",
                  width: "100%",
                }}
              >
                <RetroLogo variant={isSmall ? "body2" : "h3"}>
                  {team1.name}
                </RetroLogo>

                {team1.logo_id && (
                  <TeamLogo>
                    <Typography
                      variant="h2"
                      sx={{ color: "#ffab00", fontWeight: "bold" }}
                    >
                      {team1.logo_id}
                    </Typography>
                  </TeamLogo>
                )}
                <ScoreDisplay fontSize={isSmall ? "11px" : "16px"}>
                  {teamScores.team1}
                </ScoreDisplay>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    // label="DOUBLE SIP"
                    sx={{
                      animation: team1LifelineState.doubleSip.isActive
                        ? "pulse 1.5s infinite"
                        : "none",
                      "@keyframes pulse": {
                        "0%": {
                          boxShadow: "0 0 0 0 rgba(0, 230, 118, 0.7)",
                        },
                        "70%": {
                          boxShadow: "0 0 0 10px rgba(0, 230, 118, 0)",
                        },
                        "100%": {
                          boxShadow: "0 0 0 0 rgba(0, 230, 118, 0)",
                        },
                      },
                      backgroundColor: team1LifelineState.doubleSip.isUsed
                        ? "#616161"
                        : "#00e676",
                      color: team1LifelineState.doubleSip.isUsed
                        ? "#bdbdbd"
                        : "#000",
                      fontVariant: "body1",
                      border: team1LifelineState.doubleSip.isUsed
                        ? "2px solid #bdbdbd"
                        : "2px solid #00e676",
                      marginRight: "4px",
                      marginBottom: "4px",
                      padding: "6px",
                      boxShadow: team1LifelineState.doubleSip.isUsed
                        ? "none"
                        : "0 2px 0 rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 230, 118, 0.4)",
                      opacity: team1LifelineState.doubleSip.isUsed ? 0.6 : 1,
                      "&:hover": {
                        backgroundColor: team1LifelineState.doubleSip.isUsed
                          ? "#616161"
                          : "#00e676",
                      },
                    }}
                  >
                    <SportsBar fontSize={isSmall ? "small" : "medium"} />
                  </IconButton>
                  <IconButton
                    // label="DOUBLE UP"
                    sx={{
                      animation: team1LifelineState.doubleUp.isActive
                        ? "pulse 1.5s infinite"
                        : "none",
                      "@keyframes pulse": {
                        "0%": {
                          boxShadow: "0 0 0 0 rgba(0, 230, 118, 0.7)",
                        },
                        "70%": {
                          boxShadow: "0 0 0 10px rgba(0, 230, 118, 0)",
                        },
                        "100%": {
                          boxShadow: "0 0 0 0 rgba(0, 230, 118, 0)",
                        },
                      },
                      backgroundColor: team1LifelineState.doubleUp.isUsed
                        ? "#616161"
                        : "#00e676",
                      color: team1LifelineState.doubleUp.isUsed
                        ? "#bdbdbd"
                        : "#000",
                      fontVariant: "body1",
                      border: team1LifelineState.doubleUp.isUsed
                        ? "2px solid #bdbdbd"
                        : "2px solid #00e676",
                      marginRight: "4px",
                      marginBottom: "4px",
                      padding: "6px",
                      boxShadow: team1LifelineState.doubleUp.isUsed
                        ? "none"
                        : "0 2px 0 rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 230, 118, 0.4)",
                      opacity: team1LifelineState.doubleUp.isUsed ? 0.6 : 1,
                      "&:hover": {
                        backgroundColor: team1LifelineState.doubleUp.isUsed
                          ? "#616161"
                          : "#00e676",
                      },
                    }}
                  >
                    <LooksTwoRounded fontSize={isSmall ? "small" : "medium"} />
                  </IconButton>
                  <IconButton
                    // label="PHONE A FRIEND"
                    sx={{
                      animation: team1LifelineState.phoneAFriend.isActive
                        ? "pulse 1.5s infinite"
                        : "none",
                      "@keyframes pulse": {
                        "0%": {
                          boxShadow: "0 0 0 0 rgba(0, 230, 118, 0.7)",
                        },
                        "70%": {
                          boxShadow: "0 0 0 10px rgba(0, 230, 118, 0)",
                        },
                        "100%": {
                          boxShadow: "0 0 0 0 rgba(0, 230, 118, 0)",
                        },
                      },
                      backgroundColor: team1LifelineState.phoneAFriend.isUsed
                        ? "#616161"
                        : "#00e676",
                      color: team1LifelineState.phoneAFriend.isUsed
                        ? "#bdbdbd"
                        : "#000",
                      fontVariant: "body1",
                      border: team1LifelineState.phoneAFriend.isUsed
                        ? "2px solid #bdbdbd"
                        : "2px solid #00e676",
                      marginRight: "4px",
                      marginBottom: "4px",
                      padding: "6px",
                      boxShadow: team1LifelineState.phoneAFriend.isUsed
                        ? "none"
                        : "0 2px 0 rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 230, 118, 0.4)",
                      opacity: team1LifelineState.phoneAFriend.isUsed ? 0.6 : 1,
                      "&:hover": {
                        backgroundColor: team1LifelineState.phoneAFriend.isUsed
                          ? "#616161"
                          : "#00e676",
                      },
                    }}
                  >
                    <Phone fontSize={isSmall ? "small" : "medium"} />
                  </IconButton>
                </Box>
              </Box>
            </Box>

            {/* Team 2 */}
            <Box display="flex" flex={1} flexGrow={1}>
              <Box
                sx={{
                  textAlign: "center",
                  border: "2px dashed #ffab00",
                  borderRadius: "8px",
                  padding: 2,
                  backgroundColor: "rgba(0,0,0,0.2)",
                  width: "100%",
                }}
              >
                <RetroLogo variant={isSmall ? "body2" : "h3"}>
                  {team2.name}
                </RetroLogo>

                {team2.logo_id && (
                  <TeamLogo>
                    <Typography
                      variant="h2"
                      sx={{ color: "#ffab00", fontWeight: "bold" }}
                    >
                      {team2.logo_id}
                    </Typography>
                  </TeamLogo>
                )}
                <ScoreDisplay fontSize={isSmall ? "11px" : "16px"}>
                  {teamScores.team2}
                </ScoreDisplay>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    // label="DOUBLE SIP"
                    sx={{
                      animation: team2LifelineState.doubleSip.isActive
                        ? "pulse 1.5s infinite"
                        : "none",
                      "@keyframes pulse": {
                        "0%": {
                          boxShadow: "0 0 0 0 rgba(0, 230, 118, 0.7)",
                        },
                        "70%": {
                          boxShadow: "0 0 0 10px rgba(0, 230, 118, 0)",
                        },
                        "100%": {
                          boxShadow: "0 0 0 0 rgba(0, 230, 118, 0)",
                        },
                      },
                      backgroundColor: team2LifelineState.doubleSip.isUsed
                        ? "#616161"
                        : "#00e676",
                      color: team2LifelineState.doubleSip.isUsed
                        ? "#bdbdbd"
                        : "#000",
                      fontWeight: "bold",
                      border: team2LifelineState.doubleSip.isUsed
                        ? "2px solid #bdbdbd"
                        : "2px solid #00e676",
                      marginRight: "4px",
                      marginBottom: "4px",
                      padding: "6px",
                      boxShadow: team2LifelineState.doubleSip.isUsed
                        ? "none"
                        : "0 2px 0 rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 230, 118, 0.4)",
                      opacity: team2LifelineState.doubleSip.isUsed ? 0.6 : 1,
                      "&:hover": {
                        backgroundColor: team2LifelineState.doubleSip.isUsed
                          ? "#616161"
                          : "#00e676",
                      },
                    }}
                  >
                    <SportsBar fontSize={isSmall ? "small" : "medium"} />
                  </IconButton>
                  <IconButton
                    // ODUBLE UP
                    sx={{
                      animation: team2LifelineState.doubleUp.isActive
                        ? "pulse 1.5s infinite"
                        : "none",
                      "@keyframes pulse": {
                        "0%": {
                          boxShadow: "0 0 0 0 rgba(0, 230, 118, 0.7)",
                        },
                        "70%": {
                          boxShadow: "0 0 0 10px rgba(0, 230, 118, 0)",
                        },
                        "100%": {
                          boxShadow: "0 0 0 0 rgba(0, 230, 118, 0)",
                        },
                      },
                      backgroundColor: team2LifelineState.doubleUp.isUsed
                        ? "#616161"
                        : "#00e676",
                      color: team2LifelineState.doubleUp.isUsed
                        ? "#bdbdbd"
                        : "#000",
                      fontWeight: "bold",
                      border: team2LifelineState.doubleUp.isUsed
                        ? "2px solid #bdbdbd"
                        : "2px solid #00e676",
                      padding: "6px",
                      marginRight: "4px",
                      marginBottom: "4px",
                      boxShadow: team2LifelineState.doubleUp.isUsed
                        ? "none"
                        : "0 2px 0 rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 230, 118, 0.4)",
                      opacity: team2LifelineState.doubleUp.isUsed ? 0.6 : 1,
                      "&:hover": {
                        backgroundColor: team2LifelineState.doubleUp.isUsed
                          ? "#616161"
                          : "#00e676",
                      },
                    }}
                  >
                    <LooksTwoRounded fontSize={isSmall ? "small" : "medium"} />
                  </IconButton>
                  <IconButton
                    sx={{
                      animation: team2LifelineState.phoneAFriend.isActive
                        ? "pulse 1.5s infinite"
                        : "none",
                      "@keyframes pulse": {
                        "0%": {
                          boxShadow: "0 0 0 0 rgba(0, 230, 118, 0.7)",
                        },
                        "70%": {
                          boxShadow: "0 0 0 10px rgba(0, 230, 118, 0)",
                        },
                        "100%": {
                          boxShadow: "0 0 0 0 rgba(0, 230, 118, 0)",
                        },
                      },
                      backgroundColor: team2LifelineState.phoneAFriend.isUsed
                        ? "#616161"
                        : "#00e676",
                      color: team2LifelineState.phoneAFriend.isUsed
                        ? "#bdbdbd"
                        : "#000",
                      fontWeight: "bold",
                      border: team2LifelineState.phoneAFriend.isUsed
                        ? "2px solid #bdbdbd"
                        : "2px solid #00e676",
                      marginRight: "4px",
                      marginBottom: "4px",
                      boxShadow: team2LifelineState.phoneAFriend.isUsed
                        ? "none"
                        : "0 2px 0 rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 230, 118, 0.4)",
                      opacity: team2LifelineState.phoneAFriend.isUsed ? 0.6 : 1,
                      padding: "6px",
                      "&:hover": {
                        backgroundColor: team2LifelineState.phoneAFriend.isUsed
                          ? "#616161"
                          : "#00e676",
                      },
                    }}
                  >
                    <Phone fontSize={isSmall ? "small" : "medium"} />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </RetroScoreBoard>
      </Grid>
    </RetroContainer>
  );
};

export default Player;
