import {
  Autocomplete,
  Box,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Tables, TablesInsert } from "../../database.types";
import { Link } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";

interface GameEditorProps {
  allTeams: Tables<"teams">[];
  game: TablesInsert<"games">;
  onChangeGame: (updatedGame: TablesInsert<"games">) => void;
}

const GameEditor = (props: GameEditorProps) => {
  const { allTeams, game, onChangeGame } = props;

  const teamOneValue = (() => {
    const v = allTeams.find((t) => t.id === game.team_one_id);
    return v ? { label: v.name, value: v.id } : undefined;
  })();
  const teamTwoValue = (() => {
    const v = allTeams.find((t) => t.id === game.team_two_id);
    return v ? { label: v.name, value: v.id } : undefined;
  })();

  return (
    <Box
      display="flex"
      gap="48px"
      bgcolor="background.paper"
      alignItems="center"
      borderRadius="4px"
      padding="16px"
      overflow="auto"
    >
      <Link to={`/admin`}>
        <IconButton>
          <ArrowBack color="secondary" />
        </IconButton>
      </Link>
      {/* Team Selection */}
      <Box display="flex" gap="12px" alignItems="center">
        <Autocomplete
          options={allTeams.map((t) => ({ label: t.name, value: t.id }))}
          value={teamOneValue}
          onChange={(_, val) =>
            onChangeGame({ ...game, team_one_id: val?.value ?? null })
          }
          renderInput={(params) => (
            <TextField {...params} label="Team 1" sx={{ width: "200px" }} />
          )}
        />
        <Typography>vs.</Typography>
        <Autocomplete
          options={allTeams.map((t) => ({ label: t.name, value: t.id }))}
          value={teamTwoValue}
          onChange={(_, val) => {
            onChangeGame({ ...game, team_two_id: val?.value ?? null });
          }}
          renderInput={(params) => (
            <TextField {...params} label="Team 2" sx={{ width: "200px" }} />
          )}
        />
      </Box>

      <TextField
        value={game.name}
        onChange={(e) => onChangeGame({ ...game, name: e.target.value })}
        label="Name"
        sx={{ width: "300px" }}
      />
    </Box>
  );
};

export default GameEditor;
