import { useState, useEffect } from "react";

/**
 * Custom hook that returns the current screen dimensions
 * @returns {Object} An object containing height and width of the screen
 */
const useScreenSize = () => {
  // Initialize state with the current window dimensions
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures effect runs only on mount and unmount

  return {...screenSize, isSmall: screenSize.width < 800};
};

export default useScreenSize;
