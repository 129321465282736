import { useState, useEffect, useRef, useCallback } from "react";

/**
 * Interface for the return value of the useTimer hook
 */
interface TimerControls {
  /** Current seconds remaining in the timer */
  secondsLeft: number;
  /** Function to set the timer to a specific number of seconds */
  setTime: (seconds: number) => void;
  /** Function to start the timer */
  start: () => void;
  /** Function to pause the timer */
  pause: () => void;
  /** Function to clear and reset the timer */
  clear: () => void;
  /** Is the timer running. false = paused */
  isActive: boolean;
}

/**
 * A custom hook for controlling a timer with TypeScript type safety.
 *
 * @param onComplete - Optional callback function that runs when timer reaches zero
 * @returns Object containing timer state and control functions
 */
const useTimer = (onComplete?: () => void): TimerControls => {
  const [secondsLeft, setSecondsLeft] = useState<number>(0);
  const [isActive, setIsActive] = useState<boolean>(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const onCompleteRef = useRef<(() => void) | undefined>(onComplete);

  // Update the ref when onComplete changes
  useEffect(() => {
    onCompleteRef.current = onComplete;
  }, [onComplete]);

  // Timer effect
  useEffect(() => {
    if (isActive && secondsLeft > 0) {
      timerRef.current = setTimeout(() => {
        setSecondsLeft((seconds) => seconds - 1);
      }, 1000);
    } else if (isActive && secondsLeft === 0) {
      setIsActive(false);
      if (onCompleteRef.current) {
        onCompleteRef.current();
      }
    }

    // Cleanup function to clear the timeout
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [isActive, secondsLeft]);

  // Set time to a specific value
  const setTime = useCallback((seconds: number): void => {
    if (seconds < 0) {
      throw new Error("Timer value cannot be negative");
    }
    setSecondsLeft(seconds);
  }, []);

  // Start the timer
  const start = useCallback((): void => {
    setIsActive(true);
  }, []);

  // Pause the timer
  const pause = useCallback((): void => {
    setIsActive(false);
  }, []);

  // Clear the timer
  const clear = useCallback((): void => {
    setIsActive(false);
    setSecondsLeft(0);
  }, []);

  return {
    secondsLeft,
    setTime,
    start,
    pause,
    clear,
    isActive,
  };
};

export default useTimer;
