// src/contexts/SupabaseContext.js
import {
  AuthError,
  AuthResponse,
  AuthTokenResponsePassword,
  createClient,
  Session,
  SupabaseClient,
  User,
} from "@supabase/supabase-js";
import { Database, Tables } from "../../database.types";
import { createContext, useContext, useState, useEffect } from "react";
import leagueService from "../data/leagueService";
import { useNavigate } from "react-router-dom";

// Initialize Supabase client (replace with your actual credentials)
const supabaseUrl = "https://rewqlraaqvjaxtmaibtn.supabase.co";
const supabaseAnonKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJld3FscmFhcXZqYXh0bWFpYnRuIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDMxOTQ4NjAsImV4cCI6MjA1ODc3MDg2MH0.DiyKzwDT0AE8bB3ybe5U5tpQA6AIygQEzD8_gwVW2xg";
const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey);

interface SupabaseProviderReturn {
  supabase: SupabaseClient;
  session: Session | null;
  user: User | null;
  loading: boolean;
  signUp: (email: string, password: string) => Promise<void>;
  signIn: (
    email: string,
    password: string
  ) => Promise<AuthTokenResponsePassword>;
  signOut: () => Promise<{
    error: AuthError | null;
  }>;
  resetPassword: (email: string) => Promise<
    | {
        data: {};
        error: null;
      }
    | {
        data: null;
        error: AuthError;
      }
  >;
  league: Tables<"leagues"> | null;
  isConfirmEmail: boolean;
}

// Create context
const SupabaseContext = createContext<SupabaseProviderReturn | null>(null);

// Context provider component
export const SupabaseProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [session, setSession] = useState<Session | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [league, setLeague] = useState<Tables<"leagues"> | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [isConfirmEmail, setIsConfirmEmail] = useState<boolean>(false);

  useEffect(() => {
    // Get initial session
    const initSession = async () => {
      setLoading(true);

      // Check for existing session
      const {
        data: { session: currentSession },
      } = await supabase.auth.getSession();
      updateSession(currentSession);

      // Set up auth state change listener
      const {
        data: { subscription },
      } = supabase.auth.onAuthStateChange((_event, newSession) => {
        updateSession(newSession);
      });

      setLoading(false);

      // Clean up subscription on unmount
      return () => subscription.unsubscribe();
    };

    initSession();
  }, []);

  const updateSession = (newSession: Session | null) => {
    setSession(newSession);
    setUser(newSession?.user || null);
    if (newSession) {
      fetchLeague(newSession.user.id);
    }
  };

  const fetchLeague = async (userId: string) => {
    try {
      setLoading(true);
      const leagueRes = await leagueService.getById(supabase, userId);
      if (leagueRes) {
        setLeague(leagueRes);
      }
    } catch (err) {
      // idk
    } finally {
      setLoading(false);
    }
  };

  // Authentication functions
  // trigger on users db should handle the creation of the league row in the db
  const signUp = async (email: string, password: string) => {
    supabase.auth.signUp({ email, password });
    setIsConfirmEmail(true);
  };

  const signIn = async (email: string, password: string) =>
    await supabase.auth.signInWithPassword({ email, password });

  const signOut = () => supabase.auth.signOut();

  const resetPassword = (email: string) =>
    supabase.auth.resetPasswordForEmail(email);

  // Create value object
  const value = {
    // Supabase instance
    supabase,
    // Auth state
    session,
    user,
    loading,
    // Auth methods
    signUp,
    signIn,
    signOut,
    resetPassword,
    league,
    isConfirmEmail,
  };

  // Return provider with value
  return (
    <SupabaseContext.Provider value={value}>
      {children}
    </SupabaseContext.Provider>
  );
};

// Custom hook for easy context use
export function useSupabase() {
  const context = useContext(SupabaseContext);
  if (!context) {
    throw new Error("useSupabase must be used within a SupabaseProvider");
  }
  return context;
}
