import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import { App } from "../types/types";
import useScreenSize from "../hooks/useScreenSize";

interface GridDisplayProps {
  /**
   * Array of strings to display (up to 50)
   */
  items: App.ClassicBonusRoundAnswer[];
  /**
   * Height of each grid item (in px)
   * @default 80
   */
  itemHeight?: number;
  /**
   * Width of each grid item (in px)
   * @default 200
   */
  itemWidth?: number;
  /**
   * Gap between grid items (in px)
   * @default 16
   */
  gap?: number;
  /**
   * Maximum height of the grid container (with overflow)
   * @default 500
   */
  maxHeight?: number;
  /**
   * Additional styles for the container
   */
  containerStyle?: React.CSSProperties;
  /**
   * Additional styles for individual items
   */
  itemStyle?: React.CSSProperties;
}

/**
 * GridDisplay - A component that displays an array of strings in a responsive grid
 * using Flexbox instead of MUI Grid for more direct control
 */
const ClassicBonusRoundGrid: React.FC<GridDisplayProps> = ({
  items = [],
  itemHeight = 60,
  itemWidth = 200,
  gap = 16,
  maxHeight = 500,
  containerStyle = {},
  itemStyle = {},
}) => {
  // Limit to 50 items
  const displayItems = items.slice(0, 50);

  const { isSmall } = useScreenSize();

  return (
    <Box
      sx={{
        width: "100%",
        maxHeight,
        overflow: "auto",
        padding: isSmall ? 1 : 2,
        ...containerStyle,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: `8px`,
        }}
      >
        {displayItems.map((item, index) => (
          <Paper
            key={index}
            elevation={2}
            sx={{
              height: isSmall ? 40 : itemHeight,
              width: isSmall ? 60 : itemWidth,
              display: "flex",
              alignItems: "center",
              justifyContent: 'center',
              padding: isSmall ? 0 : 1,
              flexShrink: 0,
              backgroundColor: item.team_correct_id ? "green" : "gray",
              ...itemStyle,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textAlign: 'center',
                fontSize: isSmall ? 11 : undefined,
              }}
            >
              {item.team_correct_id ? item.text : "???"}
            </Typography>
          </Paper>
        ))}
      </Box>
    </Box>
  );
};

export default ClassicBonusRoundGrid;
