import { SupabaseProvider, useSupabase } from "./auth/useSupabase";
import { BrowserRouter, useLocation, useNavigate } from "react-router-dom";
import { Box, CircularProgress, ThemeProvider } from "@mui/material";
import AdminRoutes from "./admin/AdminRoutes";
import Auth from "./auth/Auth";
import theme from "./theme";

const AuthorizedApp = () => {
  const { session, league, loading } = useSupabase();

  const location = useLocation();
  const navigate = useNavigate();

  if (loading) {
    return (
      <Box
        height="100vh"
        width="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (location.pathname === "/auth") {
    if (session && league) {
      navigate("/admin");
    }
    return <Auth />;
  }

  if (!session) {
    navigate("/auth", { state: { from: location.pathname } });
  }

  return <AdminRoutes />;
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <SupabaseProvider>
          <BrowserRouter>
            <AuthorizedApp />
          </BrowserRouter>
        </SupabaseProvider>
      </div>
    </ThemeProvider>
  );
};

export default App;
