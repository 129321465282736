import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  Chip,
  CircularProgress,
  Divider,
  Button,
  Alert,
  Grid,
  Paper,
  TextField,
  IconButton,
  Container,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
  Snackbar,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import GroupsIcon from "@mui/icons-material/Groups";
import { useSupabase } from "../auth/useSupabase";
import { gameService } from "../data/gameService";
import { Tables, TablesInsert } from "../../database.types";
import { teamService } from "../data/teamService";
import { getEmptyGame } from "../utils/utils";
import { v4 } from "uuid";
import { useNavigate } from "react-router-dom";
import {
  CopyAll,
  Delete,
  LogoutOutlined,
  EditOutlined,
} from "@mui/icons-material";
import { RetroContainer } from "../player/Player";

const generateRandomHexCode = (): string => {
  return (
    "#" +
    Math.floor(Math.random() * 0xffffff)
      .toString(16)
      .padStart(6, "0")
  );
};

// Component for displaying game status with appropriate styling
const GameStatusBadge: React.FC<{
  game: Tables<"games">;
  winningTeamName?: string;
}> = ({ game, winningTeamName }) => {
  if (game.winning_team_id && winningTeamName) {
    return (
      <Chip
        label={`Winner: ${winningTeamName}`}
        size="small"
        color="success"
        sx={{ fontWeight: "medium" }}
      />
    );
  }
  if (!game.config_complete) {
    return (
      <Chip
        label="Setup Incomplete"
        size="small"
        color="warning"
        sx={{ fontWeight: "medium" }}
      />
    );
  } else if (game.played) {
    return (
      <Chip
        label="Completed"
        size="small"
        color="success"
        sx={{ fontWeight: "medium" }}
      />
    );
  } else {
    return (
      <Chip
        label="Ready to Play"
        size="small"
        color="info"
        sx={{ fontWeight: "medium" }}
      />
    );
  }
};

// Team display with color indicator
const TeamDisplay: React.FC<{ team: Tables<"teams"> | undefined }> = ({
  team,
}) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box
        sx={{
          width: "4px",
          height: "24px",
          backgroundColor: team?.color || "#ccc",
          marginRight: 1,
          borderRadius: "2px",
        }}
      />
      <Typography variant="body2" fontWeight="500" color="text.primary">
        {team?.name || "Unknown Team"}
      </Typography>
    </Box>
  );
};

const LeagueOverview: React.FC = () => {
  const { user, supabase, signOut, league } = useSupabase();
  const nav = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));

  // State for data
  const [games, setGames] = useState<Tables<"games">[]>([]);
  const [teams, setTeams] = useState<Tables<"teams">[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const [snackbarText, setSnackbarText] = useState<string>("");

  const [gameToStart, setGameToStart] = useState<Tables<"games"> | null>(null);

  const [confirmSignOut, setConfirmSignOut] = useState<boolean>(false);

  // Lookup maps for teams and leagues
  const [teamsMap, setTeamsMap] = useState<Record<string, Tables<"teams">>>({});

  const [newGameName, setNewGameName] = useState<string>("");
  const [newTeam, setNewTeam] = useState<TablesInsert<"teams">>({
    name: "",
    color: "",
    league_id: league?.id ?? "",
  });

  // Fetch games data
  useEffect(() => {
    initializePage();
  }, [league]);

  const initializePage = async () => {
    try {
      setLoading(true);
      await Promise.all([fetchGames(), fetchTeams()]);
    } catch (err) {
      // Error handling
    } finally {
      setLoading(false);
    }
  };

  const fetchGames = async () => {
    if (!league) {
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const gamesData = await gameService.getByLeague(supabase, league.id);

      // Sort by creation date (newest first)
      const sortedGames = [...gamesData].sort((a, b) => {
        return (
          new Date(b.created_at ?? 0).getTime() -
          new Date(a.created_at ?? 0).getTime()
        );
      });

      setGames(sortedGames);
    } catch (err) {
      console.error("Error fetching games:", err);
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setLoading(false);
    }
  };

  const fetchTeams = async () => {
    if (!league) return;

    try {
      setLoading(true);
      const teamsData = await teamService.getAllLeagueTeams(
        supabase,
        league.id
      );
      setTeams(teamsData);

      // Create teams lookup map
      const map: Record<string, Tables<"teams">> = {};
      teamsData.forEach((team: Tables<"teams">) => {
        map[team.id] = team;
      });
      setTeamsMap(map);
    } catch (err) {
      console.error("Error fetching teams:", err);
      // Don't set error, as games are more important
    } finally {
      setLoading(false);
    }
  };

  const onCreateNewGame = async () => {
    if (league) {
      setLoading(true);
      try {
        const boardId = v4();
        await gameService.upsertGameBoard(supabase, boardId, null);
        const game = await gameService.create(
          supabase,
          getEmptyGame(league.id, newGameName, boardId)
        );
        setGames([...games, game]);

        nav(`/game/${game.id}/builder`);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const newGameDisplay = () => (
    <Paper
      elevation={2}
      sx={{
        p: { xs: 2, sm: 3 },
        borderRadius: 2,
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        gap: 2,
        maxWidth: 600,
        mx: "auto",
        transition: "box-shadow 0.3s ease-in-out",
        "&:hover": {
          boxShadow: theme.shadows[4],
        },
      }}
    >
      {games.length === 0 && (
        <React.Fragment>
          <Box sx={{ mb: 2 }}>
            <SportsEsportsIcon
              sx={{ fontSize: 48, color: "primary.main", mb: 1 }}
            />
            <Typography variant="h5" gutterBottom fontWeight="600">
              No games found
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              Get started by creating your first game.
            </Typography>
          </Box>
        </React.Fragment>
      )}
      <TextField
        label="Game name"
        value={newGameName}
        onChange={(e) => setNewGameName(e.target.value)}
        variant="outlined"
        fullWidth
      />

      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={onCreateNewGame}
        disabled={!newGameName}
        size="large"
        sx={{ mt: 1 }}
      >
        Create New Game
      </Button>
    </Paper>
  );

  const onCreateNewTeam = async () => {
    if (league?.id) {
      try {
        setLoading(true);
        const newTeamRes = await teamService.create(supabase, {
          name: newTeam.name,
          color: generateRandomHexCode(),
          league_id: league.id,
        });
        setTeams([...teams, newTeamRes]);
        setNewTeam({
          name: "",
          color: "",
          league_id: league?.id ?? "",
        });
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const newTeamDisplay = () => (
    <Box sx={{ minWidth: 200, width: { xs: "100%", sm: "auto" } }}>
      <TextField
        value={newTeam.name}
        label="Team Name"
        onChange={(e) => setNewTeam({ ...newTeam, name: e.target.value })}
        size="small"
        fullWidth
        sx={{ mb: 1 }}
      />
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={onCreateNewTeam}
        disabled={!newTeam.name}
        size="small"
        fullWidth
      >
        Add Team
      </Button>
    </Box>
  );

  const onConfirmStart = async () => {
    try {
      if (gameToStart) {
        setLoading(true);
        await gameService.update(supabase, gameToStart?.id, {
          is_started: true,
        });
        setLoading(false);
        nav(`/game/${gameToStart.id}/admin`);
      }
    } catch (err) {
      // Error handling
    } finally {
      setLoading(false);
    }
  };

  // Handle loading state
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  // Handle error state
  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Alert severity="error" sx={{ mt: 2, mb: 2 }} variant="filled">
          {error.message}
        </Alert>
      </Container>
    );
  }

  // Handle no league ID
  if (!user || !league) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Alert severity="warning" variant="filled">
          No league information available. Please check your account settings.
        </Alert>
      </Container>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "16px",
        maxHeight: "calc(100vh - 32px)",
        height: "calc(100vh - 32px)",
        bgcolor: "text.primary",
        overflow: "auto",
      }}
    >
      <Box sx={{ py: { xs: 2, sm: 3 } }}>
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          flexDirection={isMobile ? "column" : "row"}
          gap={isMobile ? 2 : 0}
          mb={3}
        >
          <Typography
            variant="h4"
            component="h1"
            gutterBottom={!isMobile}
            fontWeight="bold"
            color="primary.main"
          >
            {league.name || "League Dashboard"}
          </Typography>

          <Button
            variant="outlined"
            startIcon={<LogoutOutlined />}
            onClick={() => setConfirmSignOut(true)}
            size={isMobile ? "small" : "medium"}
          >
            Sign Out
          </Button>
        </Box>

        {/* Teams Section */}
        <Paper
          elevation={2}
          sx={{
            mb: 4,
            p: { xs: 2, sm: 3 },
            borderRadius: 2,
            transition: "box-shadow 0.3s ease-in-out",
            "&:hover": {
              boxShadow: theme.shadows[4],
            },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
            <GroupsIcon sx={{ mr: 1, color: "primary.main" }} />
            <Typography variant="h6" component="h2" fontWeight="bold">
              Teams
            </Typography>
          </Box>

          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, mb: 3 }}>
            {teams.length === 0 ? (
              <Typography variant="body2" color="text.secondary">
                No teams added yet. Add your first team below.
              </Typography>
            ) : (
              teams.map((team) => (
                <Chip
                  key={team.id}
                  label={team.name}
                  sx={{
                    borderLeft: `4px solid ${team.color}`,
                    borderRadius: 1,
                    px: 1,
                    py: 2,
                    fontWeight: "medium",
                  }}
                  variant="outlined"
                />
              ))
            )}
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box>
            <Typography variant="subtitle2" gutterBottom fontWeight="medium">
              Add New Team
            </Typography>
            {newTeamDisplay()}
          </Box>
        </Paper>

        {/* Games Section */}
        <Paper
          elevation={2}
          sx={{
            p: { xs: 2, sm: 3 },
            borderRadius: 2,
            transition: "box-shadow 0.3s ease-in-out",
            "&:hover": {
              boxShadow: theme.shadows[4],
            },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
            <SportsEsportsIcon sx={{ mr: 1, color: "primary.main" }} />
            <Typography variant="h6" component="h2" fontWeight="bold">
              Games
            </Typography>
          </Box>

          <Grid container spacing={3}>
            {games.map((game, index) => {
              const teamOne =
                game.team_one_id !== null ? teamsMap[game.team_one_id] : null;
              const teamTwo =
                game.team_two_id !== null ? teamsMap[game.team_two_id] : null;

              return (
                <Grid spacing={{ xs: 12, sm: 6, md: 4 }} key={game.id}>
                  <Card
                    elevation={2}
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: 2,
                      overflow: "hidden",
                      transition: "transform 0.2s, box-shadow 0.2s",
                      "&:hover": {
                        transform: "translateY(-4px)",
                        boxShadow: 6,
                      },
                    }}
                  >
                    <CardActionArea
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: 1,
                      }}
                      onClick={() => {
                        if (game.config_complete) {
                          if (!game.is_started) {
                            setGameToStart(game);
                          } else {
                            nav(`/game/${game.id}/admin`);
                          }
                        } else {
                          nav(`/game/${game.id}/builder`);
                        }
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          p: 2,
                          borderBottom: "1px solid",
                          borderColor: "divider",
                          backgroundColor: "background.paper",
                        }}
                      >
                        <Typography
                          variant="h6"
                          fontWeight="medium"
                          noWrap
                          sx={{ maxWidth: isMobile ? "150px" : "200px" }}
                        >
                          {game.name ?? `Game ${index + 1}`}
                        </Typography>
                        <GameStatusBadge
                          game={game}
                          winningTeamName={
                            game.winning_team_id
                              ? teams.find((t) => t.id === game.winning_team_id)
                                  ?.name
                              : undefined
                          }
                        />
                      </Box>

                      <CardContent
                        sx={{
                          flexGrow: 1,
                          display: "flex",
                          flexDirection: "column",
                          p: { xs: 1.5, sm: 2 },
                        }}
                      >
                        <Box sx={{ mb: 2 }}>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            gutterBottom
                            fontWeight="medium"
                          >
                            Teams
                          </Typography>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            {teamOne ? (
                              <TeamDisplay team={teamOne} />
                            ) : (
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                Not assigned
                              </Typography>
                            )}
                            <Typography variant="body2" sx={{ mx: 1 }}>
                              vs
                            </Typography>
                            {teamTwo ? (
                              <TeamDisplay team={teamTwo} />
                            ) : (
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                Not assigned
                              </Typography>
                            )}
                          </Box>
                        </Box>

                        <Box sx={{ mb: 2, flexGrow: 1 }}>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            gutterBottom
                            fontWeight="medium"
                          >
                            Categories
                          </Typography>
                          <Stack
                            direction="row"
                            spacing={1}
                            sx={{ mb: 1, flexWrap: "wrap", gap: 0.5 }}
                          >
                            {game.team_1_niche_category && (
                              <Chip
                                label={game.team_1_niche_category}
                                size="small"
                                variant="outlined"
                                sx={{ mb: 0.5 }}
                              />
                            )}
                            {game.team_2_niche_category && (
                              <Chip
                                label={game.team_2_niche_category}
                                size="small"
                                variant="outlined"
                                sx={{ mb: 0.5 }}
                              />
                            )}
                          </Stack>
                          {game.bonus_round_type && (
                            <Chip
                              label={`Bonus: ${game.bonus_round_type}`}
                              size="small"
                              color="secondary"
                              variant="outlined"
                            />
                          )}
                        </Box>

                        {game.created_at && (
                          <Typography
                            variant="caption"
                            color="text.secondary"
                            component="div"
                            sx={{ mt: "auto" }}
                          >
                            Created{" "}
                            {format(new Date(game.created_at), "MMM d, yyyy")}
                          </Typography>
                        )}

                        {!game.is_started && game.config_complete && (
                          <Button
                            variant="contained"
                            startIcon={<EditOutlined />}
                            color="secondary"
                            size="small"
                            sx={{
                              mt: 1,
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              nav(`/game/${game.id}/builder`);
                            }}
                          >
                            Edit Config
                          </Button>
                        )}
                      </CardContent>
                    </CardActionArea>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        p: 1,
                        backgroundColor: "background.paper",
                        borderTop: "1px solid",
                        borderColor: "divider",
                      }}
                    >
                      <Tooltip title="Copy Game Link">
                        <IconButton
                          onClick={async (e) => {
                            e.stopPropagation();
                            await navigator.clipboard.writeText(
                              `${window.location.origin}/game/${game.id}`
                            );
                            setSnackbarText("Game link copied to clipboard");
                          }}
                          size="small"
                          color="primary"
                        >
                          <CopyAll fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <IconButton
                        size="small"
                        color="error"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          // TODO - delete board
                        }}
                        aria-label="delete game"
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                    </Box>
                  </Card>
                </Grid>
              );
            })}
          </Grid>

          {games.length === 0 && <Box sx={{ py: 2 }}>{newGameDisplay()}</Box>}

          {games.length > 0 && <Box sx={{ mt: 4 }}>{newGameDisplay()}</Box>}
        </Paper>
      </Box>

      {/* Confirm Start Game */}
      <Dialog
        open={!!gameToStart}
        PaperProps={{
          sx: {
            borderRadius: 2,
            p: 1,
          },
        }}
      >
        <DialogTitle sx={{ fontWeight: "bold", color: "primary.main" }}>
          Start Game
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to start "{gameToStart?.name}"? Once you
            start, you cannot edit the configuration.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={() => setGameToStart(null)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onConfirmStart}
            autoFocus
          >
            Start Game
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirm Sign Out */}
      <Dialog
        open={confirmSignOut}
        PaperProps={{
          sx: {
            borderRadius: 2,
            p: 1,
          },
        }}
      >
        <DialogTitle sx={{ fontWeight: "bold", color: "primary.main" }}>
          Sign Out
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to sign out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={() => setConfirmSignOut(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              await signOut();
              nav("/auth");
            }}
            autoFocus
          >
            Yes, Sign Out
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        autoHideDuration={2000}
        onClose={() => setSnackbarText("")}
        open={!!snackbarText}
        message={snackbarText}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: theme.palette.primary.main,
            fontWeight: 500,
          },
        }}
      />
    </Box>
  );
};

export default LeagueOverview;
