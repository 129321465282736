import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { Box, Button, IconButton, Paper, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { getRoundTitleText } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import useScreenSize from "../../hooks/useScreenSize";

export const ControlCenterRoundContainer: React.FC<
  PropsWithChildren<{
    onClickPrevious: () => void;
    onClickNext: () => void;
    currentRoundIdx: number | "bonus" | "overtime" | null;
    category?: string;
  }>
> = ({ children, onClickNext, onClickPrevious, currentRoundIdx, category }) => {
  const navigate = useNavigate();

  const { isSmall } = useScreenSize();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#121212",
        justifyContent: "space-between",
        backgroundImage: "radial-gradient(#3a3a3a 1px, transparent 0)",
        backgroundSize: "20px 20px",
        padding: isSmall ? "16px 16px 48px 16px" : "16px",
        maxHeight: "calc(100vh - 32px)",
        height: "calc(100vh - 32px)",
        color: "#f5f5f5",
        fontFamily: '"Press Start 2P", "Roboto Mono", monospace',
        overflow: "auto",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 3,
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#121212",
          justifyContent: "flex-start",
          backgroundImage: "radial-gradient(#3a3a3a 1px, transparent 0)",
          backgroundSize: "20px 20px",
          padding: "16px",
          maxHeight: "calc(100vh - 32px)",
          height: "calc(100vh - 32px)",
          color: "#f5f5f5",
          fontFamily: '"Press Start 2P", "Roboto Mono", monospace',
        }}
      >
        <Box display="flex" width="100%" justifyContent="flex-start">
          <Button
            onClick={() => {
              navigate("/admin");
            }}
            variant="text"
          >
            Back to home
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 3,
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={onClickPrevious}
            color="primary"
            size="large"
            disabled={currentRoundIdx === 0}
          >
            <NavigateBefore />
          </IconButton>

          <Typography variant="h4" align="center" gutterBottom>
            {getRoundTitleText(currentRoundIdx, category)}
          </Typography>

          <IconButton
            onClick={onClickNext}
            color="primary"
            size="large"
            disabled={currentRoundIdx === "overtime"}
          >
            {currentRoundIdx === 11 ? (
              <Button onClick={onClickNext} variant="outlined">
                Overtime
              </Button>
            ) : (
              <NavigateNext />
            )}
          </IconButton>
        </Box>
        {children}
      </Paper>
    </Box>
  );
};
