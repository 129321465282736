import {
  Tables,
  DozenSupabaseClient,
  TablesInsert,
} from "../../database.types";

// Team CRUD operations
export const teamService = {
  async getAllLeagueTeams(supabase: DozenSupabaseClient, league_id: string): Promise<Tables<"teams">[]> {
    const { data, error } = await supabase
      .from("teams")
      .select("*")
      .eq("league_id", league_id)
      .order("name");

    if (error) {
      console.error("Error fetching teams:", error);
      throw error;
    }

    return data || [];
  },

  async getByLeague(
    supabase: DozenSupabaseClient,
    leagueId: string
  ): Promise<Tables<"teams">[]> {
    const { data, error } = await supabase
      .from("teams")
      .select("*")
      .eq("league_id", leagueId)
      .order("name");

    if (error) {
      console.error(`Error fetching teams for league ${leagueId}:`, error);
      throw error;
    }

    return data || [];
  },

  async getById(
    supabase: DozenSupabaseClient,
    id: string
  ): Promise<Tables<"teams"> | null> {
    const { data, error } = await supabase
      .from("teams")
      .select("*")
      .eq("id", id)
      .single();

    if (error) {
      console.error(`Error fetching team with id ${id}:`, error);
      throw error;
    }

    return data;
  },

  async create(
    supabase: DozenSupabaseClient,
    team: TablesInsert<"teams">
  ): Promise<Tables<"teams">> {
    const { data, error } = await supabase
      .from("teams")
      .insert(team)
      .select()
      .single();

    if (error) {
      console.error("Error creating team:", error);
      throw error;
    }

    return data;
  },

  async update(
    supabase: DozenSupabaseClient,
    id: string,
    updates: Partial<Omit<Tables<"teams">, "id" | "created_at" | "updated_at">>
  ): Promise<Tables<"teams">> {
    const { data, error } = await supabase
      .from("teams")
      .update(updates)
      .eq("id", id)
      .select()
      .single();

    if (error) {
      console.error(`Error updating team with id ${id}:`, error);
      throw error;
    }

    return data;
  },

  async delete(supabase: DozenSupabaseClient, id: string): Promise<void> {
    const { error } = await supabase.from("teams").delete().eq("id", id);

    if (error) {
      console.error(`Error deleting team with id ${id}:`, error);
      throw error;
    }
  },

  async uploadLogo(supabase: DozenSupabaseClient, file: File): Promise<string> {
    const fileExt = file.name.split(".").pop();
    const fileName = `${Math.random()}.${fileExt}`;
    const filePath = `team-logos/${fileName}`;

    const { error: uploadError } = await supabase.storage
      .from("team-logos")
      .upload(filePath, file);

    if (uploadError) {
      console.error("Error uploading logo:", uploadError);
      throw uploadError;
    }

    // Create a file record in the database
    const { data: fileData, error: fileRecordError } = await supabase
      .from("files")
      .insert({
        file_name: fileName,
        file_type: file.type,
        file_path: filePath,
        file_size: file.size,
      })
      .select()
      .single();

    if (fileRecordError) {
      console.error("Error creating file record:", fileRecordError);
      throw fileRecordError;
    }

    return fileData.id;
  },
};
