import { App } from "../types/types";
import React, { useEffect, useState } from "react";
import { Box, Tabs, Tab, AppBar } from "@mui/material";
import { Check as CheckIcon, Clear as ClearIcon } from "@mui/icons-material";
import { cloneDeep } from "lodash";
import RoundEditor from "./RoundEditor";
import BonusRoundEditor from "./BonusRoundEditor";

// Type for the props
interface GameBoardEditorProps {
  initialBoard: App.Board;
  onBoardChange: (
    board: App.Board,
    team1NicheCategory?: string,
    team2NicheCategory?: string
  ) => void;
  team1NicheCategory?: string;
  team2NicheCategory?: string;
  disabled: boolean; // disabled if game is started or game isn't there or user isn't the creator
}

const getRoundNumberFromTabIndex = (tabIndex: number) => {
  if (tabIndex === 6) {
    return "bonus";
  }
  return tabIndex < 6 ? tabIndex + 1 : tabIndex;
};

const getRoundIndexFromCurrentTabIndex = (tabIndex: number) => {
  if (tabIndex === 6) {
    // not a round index - bonus round
    return undefined;
  }
  return tabIndex < 6 ? tabIndex : tabIndex - 1;
};

// Main component

const GameBoardEditor: React.FC<GameBoardEditorProps> = ({
  initialBoard,
  onBoardChange,
  team1NicheCategory,
  team2NicheCategory,
  disabled,
}) => {
  // State to track the current board and editing state
  const [board, setBoard] = useState<App.Board>(initialBoard);
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0); // between 0 and 13 - index of 6 === bonus round, 13 == bonus round
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    setBoard(initialBoard);
  }, [initialBoard]);

  // note that this is round NUMBER not round index
  const roundNumber: number | "bonus" =
    getRoundNumberFromTabIndex(currentTabIndex);

  // Check if we're on the niche category round
  const isNiche = roundNumber === 10;

  const roundIndex = getRoundIndexFromCurrentTabIndex(currentTabIndex);

  // Handle saving changes to the board
  const handleSaveBonusRoundOrOvertime = (bonusRound: App.BonusRound) => {
    if (currentTabIndex == 6) {
      onBoardChange({
        ...board,
        bonus_round: {
          ...bonusRound,
          classic_bonus_round: bonusRound.classic_bonus_round
            ? {
                ...bonusRound.classic_bonus_round,
                answers: bonusRound.classic_bonus_round.answers.map((a) => ({
                  ...a,
                  team_correct_id: null,
                })),
              }
            : null,
          closest_guess_bonus_round: bonusRound.closest_guess_bonus_round,
        },
      });
    } else if (currentTabIndex === 13) {
      onBoardChange({
        ...board,
        overtime: {
          ...bonusRound,
          classic_bonus_round: bonusRound.classic_bonus_round
            ? {
                ...bonusRound.classic_bonus_round,
                answers: bonusRound.classic_bonus_round.answers.map((a) => ({
                  ...a,
                  team_correct_id: null,
                })),
              }
            : null,
          closest_guess_bonus_round: bonusRound.closest_guess_bonus_round,
        },
      });
    }
  };

  const handleSaveRound = (
    roundIdx: number,
    round: App.Round,
    team1Niche?: string,
    team2Niche?: string
  ) => {
    const updatedRounds = cloneDeep(board.rounds);
    updatedRounds[roundIdx] = round;
    const updatedBoard: App.Board = {
      ...board,
      rounds: updatedRounds,
    };
    onBoardChange(updatedBoard, team1Niche, team2Niche);
  };

  // Check if a round is complete (has all questions and answers filled)
  const isRoundComplete = (
    roundToCheckIdx?: number,
    isBonus: boolean = false
  ): boolean => {
    const roundIdx =
      roundToCheckIdx === undefined
        ? getRoundIndexFromCurrentTabIndex(currentTabIndex)
        : roundToCheckIdx;

    if (roundToCheckIdx === 13) {
      if (board.overtime?.type === "Closest Guess Battle") {
        if (
          (board?.overtime?.closest_guess_bonus_round?.questions ?? []).length <
          6
        ) {
          return false;
        }
        if (
          !board?.overtime?.closest_guess_bonus_round?.questions.every(
            (q) => q.question && q.answer
          )
        ) {
          return false;
        }
      } else {
        return (
          !!board.overtime?.classic_bonus_round?.question &&
          board.overtime?.classic_bonus_round?.answers.length > 0 &&
          board.overtime?.classic_bonus_round?.answers.every((a) => !!a.text)
        );
      }
      return true;
    }

    if (roundIdx === undefined || isBonus) {
      if (
        board.bonus_round.type === "Classic (List)" &&
        board.bonus_round.classic_bonus_round
      ) {
        return (
          !!board.bonus_round.classic_bonus_round.question &&
          board.bonus_round.classic_bonus_round.answers.length > 20 &&
          board.bonus_round.classic_bonus_round.answers.every((a) => !!a.text)
        );
      } else {
        return (
          !!board.bonus_round.closest_guess_bonus_round &&
          board.bonus_round.closest_guess_bonus_round.questions.length > 5 &&
          board.bonus_round.closest_guess_bonus_round.questions.every(
            (q) => q.question && q.answer
          )
        );
      }
    }

    const round = board.rounds[roundIdx];
    return (
      !!(round.category || roundIdx === 9) &&
      !!round.question_1.question &&
      !!round.question_1.answer &&
      !!round.question_2.question &&
      !!round.question_2.answer
    );
  };

  // Render the tabs at the top for navigation
  const renderTabs = () => {
    return (
      <AppBar
        position="static"
        color="transparent"
        sx={{
          paddingBottom: "8px",
          borderRadius: "12px",
          boxShadow: 2,
          overflow: "hidden",
          bgcolor: "background.paper",
        }}
      >
        <Tabs
          value={currentTabIndex}
          onChange={(_, newValue) => setCurrentTabIndex(newValue)}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="primary"
          textColor="primary"
          sx={{
            "& .MuiTabs-indicator": {
              height: 3,
              borderTopLeftRadius: 3,
              borderTopRightRadius: 3,
            },
            "& .MuiTabs-scrollButtons": {
              color: "primary.main",
              "&.Mui-disabled": {
                opacity: 0.3,
              },
            },
          }}
        >
          {board.rounds.slice(0, 6).map((_, index) => {
            const isComplete = isRoundComplete(index);

            let label = `Round ${index + 1}`;

            return (
              <Tab
                key={index}
                label={label}
                icon={
                  isComplete ? (
                    <CheckIcon
                      fontSize="small"
                      sx={{ color: "success.main" }}
                    />
                  ) : (
                    <ClearIcon fontSize="small" sx={{ color: "error.main" }} />
                  )
                }
                iconPosition="end"
                sx={{
                  fontSize: { xs: "12px", sm: "14px" },
                  fontWeight: 600,
                  px: { xs: 1, sm: 2 },
                  minHeight: { xs: 42, sm: 48 },
                  my: 0.5,
                  borderRadius: 1,
                  transition: "background-color 0.2s",
                  "&.Mui-selected": {
                    fontWeight: 700,
                    color: "primary.main",
                  },
                  "&:hover": {
                    backgroundColor: "rgba(145, 49, 117, 0.04)",
                  },
                }}
              />
            );
          })}
          <Tab
            key={12}
            label="Bonus Round"
            icon={
              isRoundComplete(undefined, true) ? (
                <CheckIcon fontSize="small" sx={{ color: "success.main" }} />
              ) : (
                <ClearIcon fontSize="small" sx={{ color: "error.main" }} />
              )
            }
            iconPosition="end"
            sx={{
              fontSize: { xs: "11px", sm: "13px" },
              fontWeight: 600,
              px: { xs: 1, sm: 2 },
              minHeight: { xs: 42, sm: 48 },
              my: 0.5,
              borderRadius: 1,
              "&.Mui-selected": {
                fontWeight: 700,
                color: "secondary.main",
                bgcolor: "rgba(205, 88, 136, 0.12)",
              },
              "&:hover": {
                backgroundColor: "rgba(205, 88, 136, 0.15)",
              },
            }}
          />
          {board.rounds.slice(6, 12).map((_, idx) => {
            const index = idx + 6;
            const isComplete = isRoundComplete(index);
            const isNicheTab = index === 9;

            let label = `Round ${index + 1}`;
            if (isNicheTab) {
              label = "Niche Round";
            }

            return (
              <Tab
                key={index}
                label={label}
                icon={
                  isComplete ? (
                    <CheckIcon
                      fontSize="small"
                      sx={{ color: "success.main" }}
                    />
                  ) : (
                    <ClearIcon fontSize="small" sx={{ color: "error.main" }} />
                  )
                }
                iconPosition="end"
                sx={{
                  fontSize: { xs: "11px", sm: "13px" },
                  fontWeight: 600,
                  px: { xs: 1, sm: 2 },
                  minHeight: { xs: 42, sm: 48 },
                  my: 0.5,
                  borderRadius: 1,
                  ...(isNicheTab && {
                    "&.Mui-selected": {
                      fontWeight: 700,
                      color: "primary.dark",
                      bgcolor: "rgba(145, 49, 117, 0.12)",
                    },
                    "&:hover": {
                      backgroundColor: "rgba(145, 49, 117, 0.15)",
                    },
                  }),
                  "&.Mui-selected": {
                    fontWeight: 700,
                    color: "primary.main",
                  },
                  "&:hover": {
                    backgroundColor: "rgba(145, 49, 117, 0.04)",
                  },
                }}
              />
            );
          })}
          <Tab
            key={13}
            label="Overtime"
            icon={
              isRoundComplete(13) ? (
                <CheckIcon fontSize="small" sx={{ color: "success.main" }} />
              ) : (
                <ClearIcon fontSize="small" sx={{ color: "error.main" }} />
              )
            }
            iconPosition="end"
            sx={{
              fontSize: { xs: "11px", sm: "13px" },
              fontWeight: 600,
              px: { xs: 1, sm: 2 },
              minHeight: { xs: 42, sm: 48 },
              my: 0.5,
              borderRadius: 1,
              "&.Mui-selected": {
                fontWeight: 700,
                color: "secondary.main",
                bgcolor: "rgba(205, 88, 136, 0.12)",
              },
              "&:hover": {
                backgroundColor: "rgba(205, 88, 136, 0.15)",
              },
            }}
          />
        </Tabs>
      </AppBar>
    );
  };

  const renderPageBody = () => {
    if (currentTabIndex === 13) {
      return (
        <BonusRoundEditor
          key={currentTabIndex}
          initialBonusRound={
            board.overtime ?? {
              type: "Classic (List)",
              classic_bonus_round: null,
              closest_guess_bonus_round: null,
              winning_team_id: null,
            }
          }
          handleSaveBonusRound={handleSaveBonusRoundOrOvertime}
          isEditing={isEditing}
          disabled={disabled}
        />
      );
    }
    if (currentTabIndex === 6 || roundIndex === undefined) {
      return (
        <BonusRoundEditor
          key={currentTabIndex}
          initialBonusRound={board.bonus_round}
          handleSaveBonusRound={handleSaveBonusRoundOrOvertime}
          isEditing={isEditing}
          disabled={disabled}
        />
      );
    }
    return (
      <RoundEditor
        key={roundIndex}
        roundIdx={roundIndex}
        isNiche={isNiche}
        handleSaveRound={handleSaveRound}
        isEditing={isEditing}
        round={board.rounds[roundIndex ?? 0]} // should always be defined, otherwise it would be bonus round
        team1NicheCategory={team1NicheCategory}
        team2NicheCategory={team2NicheCategory}
        disabled={disabled}
      />
    );
  };

  return (
    <Box flex={1} overflow="hidden" display="flex" flexDirection="column">
      {renderTabs()}
      {renderPageBody()}
    </Box>
  );
};

export default GameBoardEditor;
