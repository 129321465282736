import {
  Box,
  Typography,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  IconButton,
  Card,
  CardContent,
  CardHeader,
  List,
  Paper,
  Divider,
  Stack,
  Chip,
  useTheme,
  useMediaQuery,
  Tooltip,
  Alert,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  ListAlt,
  Speed,
  QuizOutlined,
  FunctionsOutlined,
  HelpOutline,
} from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";
import { App } from "../types/types";
import { cloneDeep, debounce, isNumber, parseInt } from "lodash";
import NumberInput from "../components/NumberInput";

interface BonusRoundEditorProps {
  initialBonusRound: App.BonusRound;
  handleSaveBonusRound: (bonusRound: App.BonusRound) => void;
  isEditing: boolean;
  disabled: boolean;
}

const BonusRoundEditor = (props: BonusRoundEditorProps) => {
  const { initialBonusRound, handleSaveBonusRound, disabled } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [error, setError] = useState<string>("");
  const [saveAnimation, setSaveAnimation] = useState(false);

  const [bonusRound, setBonusRound] =
    useState<App.BonusRound>(initialBonusRound);

  const [classicBonusRoundQuestion, setClassicBonusRoundQuestion] =
    useState<string>(initialBonusRound.classic_bonus_round?.question ?? "");
  const [classicBonusRoundAnswers, setClassicBonusRoundAnswers] = useState<
    App.ClassicBonusRoundAnswer[]
  >(initialBonusRound.classic_bonus_round?.answers ?? []);

  const [closestGuessQuestions, setClosestGuessQuestions] = useState<
    App.ClosestGuessBonusRoundQuestion[]
  >(initialBonusRound.closest_guess_bonus_round?.questions ?? []);

  useEffect(() => {
    debouncedSaveBonusRound(
      bonusRound,
      classicBonusRoundQuestion,
      classicBonusRoundAnswers,
      closestGuessQuestions
    );
  }, [
    classicBonusRoundAnswers,
    classicBonusRoundQuestion,
    bonusRound,
    closestGuessQuestions,
  ]);

  useEffect(() => {
    if (
      bonusRound.type === "Classic (List)" &&
      classicBonusRoundAnswers.length === 0
    ) {
      setClassicBonusRoundAnswers([{ team_correct_id: null, text: "" }]);
    }
    if (
      bonusRound.type === "Closest Guess Battle" &&
      closestGuessQuestions.length === 0
    ) {
      setClosestGuessQuestions([
        {
          question: "",
          answer: null,
          team_1_answer: null,
          team_2_answer: null,
        },
      ]);
    }
  }, [bonusRound.type]);

  const onSaveBonusRound = (
    bonusRoundArg: App.BonusRound,
    classicBRQuestion: string,
    classicBRAnswers: App.ClassicBonusRoundAnswer[],
    closestGuessBRQuestions: App.ClosestGuessBonusRoundQuestion[]
  ) => {
    if (bonusRoundArg.type === "Classic (List)") {
      handleSaveBonusRound({
        type: "Classic (List)",
        winning_team_id: null,
        classic_bonus_round: {
          question: classicBRQuestion,
          answers: classicBRAnswers.map((a) => ({
            ...a,
            team_correct_id: null,
          })),
          team1Answers: [],
          team2Answers: [],
        },
        closest_guess_bonus_round: null,
      });
      // Show save animation
      setSaveAnimation(true);
      setTimeout(() => setSaveAnimation(false), 1000);
    } else if (bonusRoundArg.type === "Closest Guess Battle") {
      if (closestGuessBRQuestions.some((q) => !isNumber(q.answer))) {
        setError("Not all answers are numbers");
        return;
      }
      handleSaveBonusRound({
        type: "Closest Guess Battle",
        winning_team_id: null,
        classic_bonus_round: null,
        closest_guess_bonus_round: {
          questions: closestGuessBRQuestions.map((q) => ({
            ...q,
            team_1_answer: null,
            team_2_answer: null,
          })),
        },
      });
      // Show save animation
      setSaveAnimation(true);
      setTimeout(() => setSaveAnimation(false), 1000);
    }
  };

  const debouncedSaveBonusRound = useCallback(
    debounce(onSaveBonusRound, 300),
    []
  );

  return (
    <Box
      sx={{
        mt: 2,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: 2,
          boxShadow: 2,
          transition: "box-shadow 0.3s ease",
          "&:hover": {
            boxShadow: 4,
          },
        }}
      >
        <CardHeader
          title={
            <Typography
              variant="h5"
              component="h2"
              fontWeight="bold"
              color="primary"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                fontSize: { xs: "1.2rem", sm: "1.5rem" },
              }}
            >
              {bonusRound.type === "Classic (List)" ? <ListAlt /> : <Speed />}
              Bonus Round Editor
            </Typography>
          }
          action={
            <Tooltip title="Save Bonus Round">
              <IconButton
                onClick={() =>
                  onSaveBonusRound(
                    bonusRound,
                    classicBonusRoundQuestion,
                    classicBonusRoundAnswers,
                    closestGuessQuestions
                  )
                }
                color="primary"
                sx={{
                  transition: "transform 0.2s",
                  transform: saveAnimation ? "scale(1.2)" : "scale(1)",
                  bgcolor: saveAnimation
                    ? "rgba(145, 49, 117, 0.1)"
                    : "transparent",
                }}
              >
                <SaveIcon />
              </IconButton>
            </Tooltip>
          }
          sx={{
            borderBottom: `1px solid ${theme.palette.divider}`,
            bgcolor: "background.default",
            p: { xs: 2, sm: 3 },
          }}
        />
        <CardContent
          sx={{
            // p: { xs: 2, sm: 3 },
            // flexGrow: 1,
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          {error && (
            <Alert severity="error" onClose={() => setError("")}>
              {error}
            </Alert>
          )}

          <FormControl fullWidth margin="normal">
            <InputLabel>Bonus Round Type</InputLabel>
            <Select
              value={bonusRound.type}
              label="Bonus Round Type"
              onChange={(e) =>
                setBonusRound({
                  ...bonusRound,
                  type: e.target.value as App.BonusRoundType,
                })
              }
              disabled={disabled}
            >
              <MenuItem value="Classic (List)">
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <ListAlt color="primary" />
                  <Typography>Classic (List)</Typography>
                </Box>
              </MenuItem>
              <MenuItem value="Closest Guess Battle">
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Speed color="secondary" />
                  <Typography>Closest Guess Battle</Typography>
                </Box>
              </MenuItem>
            </Select>
          </FormControl>

          {bonusRound.type === "Classic (List)" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                // overflow: "auto",
              }}
            >
              <Paper
                elevation={1}
                sx={{
                  p: { xs: 2, sm: 3 },
                  mb: 3,
                  borderRadius: 2,
                  border: `1px solid ${theme.palette.primary.light}`,
                  borderLeft: `4px solid ${theme.palette.primary.main}`,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    color: "primary.main",
                    fontWeight: "bold",
                  }}
                >
                  <QuizOutlined />
                  Main Question
                </Typography>

                <TextField
                  label="Classic Bonus Round Question"
                  value={classicBonusRoundQuestion}
                  onChange={(e) => setClassicBonusRoundQuestion(e.target.value)}
                  fullWidth
                  disabled={disabled}
                  multiline
                  rows={2}
                  margin="normal"
                  variant="outlined"
                  required
                  InputProps={{
                    startAdornment: (
                      <HelpOutline color="primary" sx={{ mr: 1, mb: 2 }} />
                    ),
                  }}
                  placeholder="Enter the main question for the bonus round"
                />
              </Paper>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  // overflow: "auto",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    // mb: 2,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      color: "secondary.main",
                      fontWeight: "bold",
                    }}
                  >
                    <ListAlt />
                    Answers ({classicBonusRoundAnswers.length})
                  </Typography>

                  <Button
                    startIcon={<AddIcon />}
                    variant="outlined"
                    disabled={disabled}
                    color="secondary"
                    onClick={() => {
                      setClassicBonusRoundAnswers((a) => [
                        ...a,
                        { team_correct_id: null, text: "" },
                      ]);
                    }}
                    size={isMobile ? "small" : "medium"}
                  >
                    Add Answer
                  </Button>
                </Box>

                <Box
                  sx={{
                    flexGrow: 1,
                    // overflow: "auto",
                    pr: 1,
                  }}
                >
                  <Paper
                    elevation={1}
                    sx={{
                      borderRadius: 2,
                      overflow: "hidden",
                      // mb: 2,
                    }}
                  >
                    {classicBonusRoundAnswers.map((answer, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          p: 1.5,
                          borderBottom:
                            index !== classicBonusRoundAnswers.length - 1
                              ? `1px solid ${theme.palette.divider}`
                              : "none",
                          bgcolor:
                            index % 2 === 0
                              ? "background.paper"
                              : "background.default",
                        }}
                      >
                        <Chip
                          label={index + 1}
                          size="small"
                          color="primary"
                          sx={{ mr: 1, minWidth: 32 }}
                        />
                        <TextField
                          label={`Answer ${index + 1}`}
                          id={`classic-answer-input-${index}`}
                          value={answer.text}
                          onChange={(e) => {
                            const answers = [...classicBonusRoundAnswers];
                            answers[index].text = e.target.value;
                            setClassicBonusRoundAnswers(answers);
                          }}
                          fullWidth
                          disabled={disabled}
                          margin="dense"
                          variant="outlined"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              setClassicBonusRoundAnswers((p) => [
                                ...p,
                                { team_correct_id: null, text: "" },
                              ]);
                              setTimeout(() => {
                                document
                                  .getElementById(
                                    `classic-answer-input-${index + 1}`
                                  )
                                  ?.focus();
                                document
                                  .getElementById(
                                    `classic-answer-input-${index + 1}`
                                  )
                                  ?.scrollIntoView({ behavior: "smooth" });
                              }, 50);
                            }
                          }}
                        />
                        <Tooltip title="Remove Answer">
                          <IconButton
                            color="error"
                            onClick={() => {
                              const answers = cloneDeep(
                                classicBonusRoundAnswers
                              );
                              answers.splice(index, 1);
                              setClassicBonusRoundAnswers(answers);
                            }}
                            sx={{ ml: 1 }}
                            size={isMobile ? "small" : "medium"}
                            disabled={disabled}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    ))}
                  </Paper>
                </Box>
              </Box>
            </Box>
          )}

          {bonusRound.type === "Closest Guess Battle" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                // overflow: "auto",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  // mb: 2,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    color: "secondary.main",
                    fontWeight: "bold",
                  }}
                >
                  <Speed />
                  Questions & Answers ({closestGuessQuestions.length})
                </Typography>

                <Button
                  startIcon={<AddIcon />}
                  variant="outlined"
                  color="secondary"
                  disabled={disabled}
                  onClick={() =>
                    setClosestGuessQuestions([
                      ...closestGuessQuestions,
                      {
                        question: "",
                        answer: null,
                        team_1_answer: null,
                        team_2_answer: null,
                      },
                    ])
                  }
                  size={isMobile ? "small" : "medium"}
                >
                  Add Question
                </Button>
              </Box>

              <Box
                sx={{
                  flexGrow: 1,
                  // overflow: "auto",
                  pr: 1,
                }}
              >
                <Paper
                  elevation={1}
                  sx={{
                    borderRadius: 2,
                    overflow: "hidden",
                    // mb: 2,
                  }}
                >
                  {closestGuessQuestions.map((q, idx) => (
                    <Stack
                      key={idx}
                      spacing={2}
                      sx={{
                        p: 2,
                        borderBottom:
                          idx !== closestGuessQuestions.length - 1
                            ? `1px solid ${theme.palette.divider}`
                            : "none",
                        bgcolor:
                          idx % 2 === 0
                            ? "background.paper"
                            : "background.default",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <Chip
                          label={idx + 1}
                          size="small"
                          color="secondary"
                          sx={{ minWidth: 32 }}
                        />
                        <Typography fontWeight="medium">
                          Question {idx + 1}
                        </Typography>

                        <Box sx={{ flexGrow: 1 }} />

                        <Tooltip title="Remove Question">
                          <IconButton
                            color="error"
                            onClick={() => {
                              const updatedList = [...closestGuessQuestions];
                              updatedList.splice(idx, 1);
                              setClosestGuessQuestions(updatedList);
                            }}
                            disabled={disabled}
                            size={isMobile ? "small" : "medium"}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>

                      <TextField
                        label="Question"
                        value={q.question}
                        onChange={(e) => {
                          const updatedList = [...closestGuessQuestions];
                          updatedList[idx].question = e.target.value;
                          setClosestGuessQuestions(updatedList);
                        }}
                        fullWidth
                        multiline
                        disabled={disabled}
                        rows={2}
                        variant="outlined"
                        placeholder="Enter the question"
                        InputProps={{
                          startAdornment: (
                            <HelpOutline
                              color="secondary"
                              sx={{ mr: 1, mb: 2 }}
                            />
                          ),
                        }}
                      />

                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <FunctionsOutlined color="primary" />
                        <Typography variant="body2" sx={{ mr: 1 }}>
                          Correct Answer:
                        </Typography>
                        <NumberInput
                          value={q.answer}
                          onChange={(n) => {
                            const updatedList = [...closestGuessQuestions];
                            updatedList[idx].answer = n;
                            setClosestGuessQuestions(updatedList);
                          }}
                          disabled={disabled}
                          sx={{
                            width: { xs: "100%", sm: "180px" },
                          }}
                          // size="small"
                          placeholder="Enter number"
                        />
                      </Box>
                    </Stack>
                  ))}
                </Paper>
              </Box>
            </Box>
          )}

          {/* Mobile Save Button */}
          {isMobile && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              disabled={disabled}
              onClick={() =>
                onSaveBonusRound(
                  bonusRound,
                  classicBonusRoundQuestion,
                  classicBonusRoundAnswers,
                  closestGuessQuestions
                )
              }
              fullWidth
              sx={{
                // mt: 2,
                position: "sticky",
                bottom: 0,
                py: 1.5,
              }}
            >
              Save Bonus Round
            </Button>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default BonusRoundEditor;
