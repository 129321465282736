import {
  Box,
  Typography,
  Paper,
  TextField,
  Grid,
  Divider,
  IconButton,
  useTheme,
  useMediaQuery,
  Card,
  CardContent,
  Chip,
  Button,
  Stack,
  LinearProgress,
  Avatar,
  Tooltip,
} from "@mui/material";
import { App } from "../../types/types";
import { useCallback, useEffect, useState } from "react";
import { ControlCenterRoundContainer } from "./ControlCenterComponents";
import { debounce, isNumber } from "lodash";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ScoreboardIcon from "@mui/icons-material/Scoreboard";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";

interface ClosestGuessBonusRoundAdminProps {
  bonusRound: App.ClosestGuessBonusRoundBoard;
  onBonusRoundChange: (bonusRound: App.ClosestGuessBonusRoundBoard) => void;
  currentRoundIdx: number | "bonus" | "overtime" | null;
  team1Name: string;
  team2Name: string;
  setCurrentRoundIdx: (n: number) => void;
}

const ClosestGuessBonusRoundAdmin = (
  props: ClosestGuessBonusRoundAdminProps
) => {
  const {
    bonusRound: bonusRoundProp,
    onBonusRoundChange,
    currentRoundIdx,
    team1Name,
    team2Name,
    setCurrentRoundIdx,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [team1Score, setTeam1Score] = useState<number>(0);
  const [team2Score, setTeam2Score] = useState<number>(0);

  const [bonusRound, setBonusRound] =
    useState<App.ClosestGuessBonusRoundBoard>(bonusRoundProp);

  useEffect(() => {
    debouncedOnBonusRoundChange(bonusRound);
  }, [bonusRound]);

  // Calculate scores from all question results
  useEffect(() => {
    let score1 = 0;
    let score2 = 0;

    bonusRound.questions.forEach((question) => {
      const answer = question.answer;
      if (answer === null) return;

      const team1Answer = question.team_1_answer;
      const team2Answer = question.team_2_answer;

      if (team1Answer === null || team2Answer === null) return;

      const team1Diff = Math.abs(team1Answer - answer);
      const team2Diff = Math.abs(team2Answer - answer);

      if (team1Diff < team2Diff) {
        score1++;
      } else if (team2Diff < team1Diff) {
        score2++;
      }
      // If equal, no points awarded
    });

    setTeam1Score(score1);
    setTeam2Score(score2);
  }, [bonusRound]);

  const debouncedOnBonusRoundChange = useCallback(
    debounce(
      (br: App.ClosestGuessBonusRoundBoard) => onBonusRoundChange(br),
      300
    ),
    []
  );

  const handleTeamAnswerChange = (team: 1 | 2, value: string) => {
    const numValue = value === "" ? null : Number(value);

    const updatedQuestions = [...bonusRound.questions];
    const updatedQuestion = { ...updatedQuestions[currentQuestionIndex] };

    if (team === 1) {
      updatedQuestion.team_1_answer = numValue;
    } else {
      updatedQuestion.team_2_answer = numValue;
    }

    updatedQuestions[currentQuestionIndex] = updatedQuestion;

    setBonusRound({
      ...bonusRound,
      questions: updatedQuestions,
    });
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < bonusRound.questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const currentQuestion = bonusRound.questions[currentQuestionIndex];
  const curAnswer = currentQuestion?.answer;

  if (!currentQuestion || curAnswer === null) {
    return (
      <Typography color="error">
        No answer found for question. This is no bueno.
      </Typography>
    );
  }

  const team1Difference: string = isNumber(currentQuestion.team_1_answer)
    ? Math.abs(currentQuestion.team_1_answer - curAnswer).toString()
    : "N/A";

  const team2Difference: string = isNumber(currentQuestion.team_2_answer)
    ? Math.abs(currentQuestion.team_2_answer - curAnswer).toString()
    : "N/A";

  let winner: 1 | 2 | null = null;
  if (
    team1Difference === "N/A" ||
    team2Difference === "N/A" ||
    team1Difference === team2Difference
  ) {
    winner = null;
  } else if (parseFloat(team1Difference) > parseFloat(team2Difference)) {
    winner = 2;
  } else if (parseFloat(team2Difference) > parseFloat(team1Difference)) {
    winner = 1;
  }

  // Calculate progress percentage
  const progressPercentage =
    ((currentQuestionIndex + 1) / bonusRound.questions.length) * 100;

  return (
    <ControlCenterRoundContainer
      onClickNext={() => setCurrentRoundIdx(6)}
      onClickPrevious={() =>
        setCurrentRoundIdx(currentRoundIdx === "overtime" ? 11 : 5)
      }
      currentRoundIdx={currentRoundIdx}
    >
      <Paper
        elevation={3}
        sx={{
          p: { xs: 2, sm: 3 },
          mb: 2,
          borderRadius: 2,
          overflow: "auto",
          transition: "box-shadow 0.3s ease-in-out",
          "&:hover": {
            boxShadow: theme.shadows[5],
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography
            variant="h5"
            component="h1"
            color="primary.main"
            sx={{
              fontWeight: 600,
            }}
          >
            Closest Guess Bonus Round
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              p: 1,
              borderRadius: 2,
              bgcolor: "background.paper",
              boxShadow: 1,
            }}
          >
            <ScoreboardIcon color="primary" />
            <Typography variant="subtitle1" fontWeight="bold">
              Score:
            </Typography>
            <Chip
              icon={<LooksOneIcon />}
              label={team1Score}
              color="primary"
              variant="outlined"
              size={isMobile ? "small" : "medium"}
            />
            <Typography variant="body2" fontWeight="medium">
              vs
            </Typography>
            <Chip
              icon={<LooksTwoIcon />}
              label={team2Score}
              color="secondary"
              variant="outlined"
              size={isMobile ? "small" : "medium"}
            />
          </Box>
        </Box>

        <LinearProgress
          variant="determinate"
          value={progressPercentage}
          color="secondary"
          sx={{ mb: 2, height: 6, borderRadius: 3 }}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Button
            onClick={handlePreviousQuestion}
            disabled={currentQuestionIndex === 0}
            startIcon={<KeyboardArrowLeftIcon />}
            size={isMobile ? "small" : "medium"}
            variant="outlined"
          >
            {!isMobile && "Previous"}
          </Button>

          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "medium",
              px: 2,
              py: 1,
              borderRadius: 1,
              backgroundColor: "background.paper",
              boxShadow: 1,
            }}
          >
            Question {currentQuestionIndex + 1} of {bonusRound.questions.length}
          </Typography>

          <Button
            onClick={handleNextQuestion}
            disabled={currentQuestionIndex === bonusRound.questions.length - 1}
            endIcon={<KeyboardArrowRightIcon />}
            size={isMobile ? "small" : "medium"}
            variant="outlined"
          >
            {!isMobile && "Next"}
          </Button>
        </Box>

        <Card
          sx={{
            mb: 4,
            borderRadius: 2,
            boxShadow: 2,
            backgroundColor: theme.palette.background.default,
          }}
        >
          <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
            <Typography
              variant="h6"
              gutterBottom
              color="primary.main"
              sx={{ display: "flex", alignItems: "center", gap: 1 }}
            >
              <QuestionMarkIcon fontSize="small" />
              Question:
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{
                fontSize: { xs: "1rem", sm: "1.1rem" },
                mb: 3,
              }}
            >
              {currentQuestion.question}
            </Typography>

            <Divider sx={{ my: 2 }} />

            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography
                variant="h6"
                color="secondary.main"
                sx={{ display: "flex", alignItems: "center", gap: 1 }}
              >
                <CheckCircleOutlineIcon fontSize="small" />
                Correct Answer:
              </Typography>
              <Chip
                label={curAnswer}
                color="secondary"
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                  height: "auto",
                  py: 0.5,
                }}
              />
            </Box>
          </CardContent>
        </Card>

        <Grid container spacing={3}>
          <Grid spacing={{ xs: 12, md: 6 }}>
            <Card
              sx={{
                borderRadius: 2,
                height: "100%",
                transition: "transform 0.2s",
                transform: winner === 1 ? "scale(1.02)" : "scale(1)",
                boxShadow: winner === 1 ? theme.shadows[8] : theme.shadows[1],
                border:
                  winner === 1
                    ? `2px solid ${theme.palette.secondary.main}`
                    : "none",
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 600,
                      color: winner === 1 ? "secondary.main" : "text.primary",
                    }}
                  >
                    {team1Name}
                  </Typography>
                  {winner === 1 && (
                    <Chip
                      icon={<EmojiEventsIcon />}
                      label="Winner"
                      color="secondary"
                      sx={{ fontWeight: "bold" }}
                    />
                  )}
                </Box>

                <TextField
                  fullWidth
                  label="Team 1 Answer"
                  type="number"
                  value={currentQuestion.team_1_answer ?? ""}
                  onChange={(e) => handleTeamAnswerChange(1, e.target.value)}
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    sx: { fontWeight: "medium" },
                  }}
                />

                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  sx={{
                    mt: 3,
                    p: 2,
                    backgroundColor: theme.palette.background.default,
                    borderRadius: 1,
                  }}
                >
                  <Typography variant="body2" color="text.secondary">
                    Difference:
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color: winner === 1 ? "secondary.main" : "text.primary",
                    }}
                  >
                    {team1Difference}
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid spacing={{ xs: 12, md: 6 }}>
            <Card
              sx={{
                borderRadius: 2,
                height: "100%",
                transition: "transform 0.2s",
                transform: winner === 2 ? "scale(1.02)" : "scale(1)",
                boxShadow: winner === 2 ? theme.shadows[8] : theme.shadows[1],
                border:
                  winner === 2
                    ? `2px solid ${theme.palette.secondary.main}`
                    : "none",
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 600,
                      color: winner === 2 ? "secondary.main" : "text.primary",
                    }}
                  >
                    {team2Name}
                  </Typography>
                  {winner === 2 && (
                    <Chip
                      icon={<EmojiEventsIcon />}
                      label="Winner"
                      color="secondary"
                      sx={{ fontWeight: "bold" }}
                    />
                  )}
                </Box>

                <TextField
                  fullWidth
                  label="Team 2 Answer"
                  type="number"
                  value={currentQuestion.team_2_answer ?? ""}
                  onChange={(e) => handleTeamAnswerChange(2, e.target.value)}
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    sx: { fontWeight: "medium" },
                  }}
                />

                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  sx={{
                    mt: 3,
                    p: 2,
                    backgroundColor: theme.palette.background.default,
                    borderRadius: 1,
                  }}
                >
                  <Typography variant="body2" color="text.secondary">
                    Difference:
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color: winner === 2 ? "secondary.main" : "text.primary",
                    }}
                  >
                    {team2Difference}
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {winner !== null && (
          <Card
            sx={{
              mt: 3,
              p: 2,
              backgroundColor: theme.palette.secondary.main,
              borderRadius: 2,
              boxShadow: 3,
            }}
          >
            <CardContent
              sx={{
                textAlign: "center",
                p: { xs: 1, sm: 2 },
                "&:last-child": { pb: { xs: 1, sm: 2 } },
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap={1}
              >
                <EmojiEventsIcon
                  sx={{ color: "white", fontSize: { xs: 24, sm: 30 } }}
                />
                <Typography
                  variant="h6"
                  sx={{ color: "white", fontWeight: "bold" }}
                >
                  {winner === 1 ? team1Name : team2Name} wins this question!
                </Typography>
              </Box>
            </CardContent>
          </Card>
        )}

        {/* Question Results Tracker */}
        <Card sx={{ mt: 3, borderRadius: 2 }}>
          <CardContent>
            <Typography
              variant="h6"
              sx={{
                mb: 2,
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <ScoreboardIcon color="primary" fontSize="small" />
              Round Results
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
              }}
            >
              {bonusRound.questions.map((question, index) => {
                // Determine winner for this question
                let questionWinner: 1 | 2 | null = null;
                const answer = question.answer;

                if (
                  answer !== null &&
                  question.team_1_answer !== null &&
                  question.team_2_answer !== null
                ) {
                  const team1Diff = Math.abs(question.team_1_answer - answer);
                  const team2Diff = Math.abs(question.team_2_answer - answer);

                  if (team1Diff < team2Diff) {
                    questionWinner = 1;
                  } else if (team2Diff < team1Diff) {
                    questionWinner = 2;
                  }
                }

                return (
                  <Tooltip
                    key={index}
                    title={`Question ${index + 1}: ${
                      questionWinner === null
                        ? "No winner yet"
                        : `Team ${questionWinner} won`
                    }`}
                    arrow
                  >
                    <Avatar
                      sx={{
                        width: 36,
                        height: 36,
                        backgroundColor:
                          questionWinner === 1
                            ? theme.palette.primary.main
                            : questionWinner === 2
                            ? theme.palette.secondary.main
                            : "grey.300",
                        cursor: "pointer",
                        border:
                          index === currentQuestionIndex
                            ? "2px solid black"
                            : "none",
                        fontWeight: "bold",
                        "&:hover": {
                          opacity: 0.9,
                          transform: "scale(1.1)",
                        },
                        transition: "transform 0.2s",
                      }}
                      onClick={() => setCurrentQuestionIndex(index)}
                    >
                      {index + 1}
                    </Avatar>
                  </Tooltip>
                );
              })}
            </Box>
          </CardContent>
        </Card>
      </Paper>
    </ControlCenterRoundContainer>
  );
};

export default ClosestGuessBonusRoundAdmin;
