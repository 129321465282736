import {
  Box,
  styled,
  Paper,
  Card,
  Typography,
  ImageList,
  ImageListItem,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { createClient } from "@supabase/supabase-js";
import { useSupabase } from "../auth/useSupabase";
import useScreenSize from "../hooks/useScreenSize";

const RetroQuestion = styled(Paper)(({ theme }) => ({
  backgroundColor: "#003366",
  color: "white",
  borderRadius: "8px",
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
  border: "3px solid #ffab00",
  boxShadow:
    "0 6px 0 rgba(0, 0, 0, 0.3), inset 0 0 12px rgba(255, 171, 0, 0.1)",
  position: "relative",
}));

const RetroAnswer = styled(Paper)(({ theme }) => ({
  backgroundColor: "#006064",
  color: "white",
  borderRadius: "8px",
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(4),
  border: "3px solid #00e676",
  boxShadow:
    "0 6px 0 rgba(0, 0, 0, 0.3), inset 0 0 12px rgba(0, 230, 118, 0.1)",
}));

const RetroAttachment = styled(Card)(({ theme }) => ({
  backgroundColor: "#424242",
  color: "white",
  margin: "0 auto",
  borderRadius: "8px",
  overflow: "hidden",
  border: "3px solid #ffab00",
  boxShadow: "0 6px 0 rgba(0, 0, 0, 0.3)",
}));

const AttachmentLabel = styled(Typography)(({ theme }) => ({
  backgroundColor: "#ffab00",
  color: "#000",
  fontWeight: "bold",
  padding: theme.spacing(0.5, 1),
  textAlign: "center",
  borderBottomLeftRadius: "4px",
  borderBottomRightRadius: "4px",
  textTransform: "uppercase",
}));

// Type for attachment with URL for display
interface AttachmentWithUrl {
  id: string;
  url: string;
}

interface StandardQuestionDisplayProps {
  question: string;
  answer: string;
  attachmentIds: string[];
  showQuestion: boolean;
  showAnswer: boolean;
}

const StandardQuestionDisplay = (props: StandardQuestionDisplayProps) => {
  const { question, showAnswer, showQuestion, answer, attachmentIds } = props;
  const [attachments, setAttachments] = useState<AttachmentWithUrl[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { supabase } = useSupabase();

  const { isSmall } = useScreenSize();

  // Use refs to track previous props to prevent unnecessary fetches
  const prevAttachmentIdsRef = useRef<string[]>([]);
  const hasLoadedRef = useRef<boolean>(false);

  // Check if attachment IDs array has changed
  const haveAttachmentIdsChanged = (): boolean => {
    if (prevAttachmentIdsRef.current.length !== attachmentIds.length) {
      return true;
    }

    return !attachmentIds.every((id) =>
      prevAttachmentIdsRef.current.includes(id)
    );
  };

  // Load attachments only when necessary - avoid reloading on answer toggle
  useEffect(() => {
    // Process loading logic only if question is shown and we have attachment IDs
    if (showQuestion && attachmentIds && attachmentIds.length > 0) {
      const shouldLoadAttachments =
        // Haven't loaded yet
        !hasLoadedRef.current ||
        // Attachment IDs have changed
        haveAttachmentIdsChanged();

      if (shouldLoadAttachments) {
        loadAttachments();
        // Update ref values
        prevAttachmentIdsRef.current = [...attachmentIds];
        hasLoadedRef.current = true;
      }
    }

    // If component unmounts, reset the loaded state
    return () => {
      if (!showQuestion) {
        hasLoadedRef.current = false;
      }
    };
  }, [attachmentIds, showQuestion]);

  const loadAttachments = async () => {
    if (!attachmentIds || attachmentIds.length === 0) return;

    setIsLoading(true);

    try {
      // Check if we already have some of these attachments loaded
      // to avoid re-fetching them
      const existingAttachments: { [key: string]: string } = {};
      attachments.forEach((att) => {
        existingAttachments[att.id] = att.url;
      });

      // Only fetch URLs for attachments we don't already have
      const attachmentsToFetch = attachmentIds.filter(
        (id) => !existingAttachments[id]
      );

      let newAttachments: AttachmentWithUrl[] = [];

      if (attachmentsToFetch.length > 0) {
        const fetchedAttachments = await Promise.all(
          attachmentsToFetch.map(async (id) => {
            // Get a signed URL for the attachment
            const { data, error } = await supabase.storage
              .from("dozen-attachments")
              .createSignedUrl(`attachments/${id}`, 3600); // 1 hour expiry

            if (error) {
              console.error("Error getting signed URL:", error);
              return { id, url: "" };
            }

            return { id, url: data?.signedUrl || "" };
          })
        );

        // Filter out any attachments that failed to get a URL
        newAttachments = fetchedAttachments.filter((a) => a.url);
      }

      // Combine existing and new attachments, maintaining order from attachmentIds
      const allAttachments = attachmentIds
        .map((id) => {
          // Return existing attachment if we have it
          if (existingAttachments[id]) {
            return { id, url: existingAttachments[id] };
          }

          // Otherwise find it in the newly fetched ones
          const newAtt = newAttachments.find((a) => a.id === id);
          return newAtt || { id, url: "" };
        })
        .filter((a) => a.url); // Remove any without URLs

      setAttachments(allAttachments);
    } catch (error) {
      console.error("Error loading attachments:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Get letter label for attachment (a, b, c, etc.)
  const getLetterLabel = (index: number): string => {
    return String.fromCharCode(97 + index); // 97 is the ASCII code for 'a'
  };

  return (
    <Box>
      {/* Attachments Section */}
      {showQuestion && attachmentIds && attachmentIds.length > 0 && (
        <Box mt={4} mb={2}>
          {isLoading ? (
            <Box display="flex" justifyContent="center" my={4}>
              <CircularProgress />
            </Box>
          ) : (
            <ImageList
              sx={{
                gridTemplateColumns:
                  "repeat(auto-fill, minmax(250px, 1fr))!important",
                gap: "16px!important",
                display: "flex",
                justifyContent: "center",
              }}
              gap={16}
            >
              {attachments.map((attachment, index) => (
                <ImageListItem
                  key={attachment.id}
                  sx={{ overflow: "hidden", borderRadius: "8px" }}
                >
                  <RetroAttachment>
                    <img
                      src={attachment.url}
                      alt={`Attachment ${getLetterLabel(index)}`}
                      loading="lazy"
                      style={{
                        width: "100%",
                        objectFit: "cover",
                        display: "block",
                        height: "auto",
                        maxHeight: "300px",
                      }}
                    />
                    <AttachmentLabel>{getLetterLabel(index)}</AttachmentLabel>
                  </RetroAttachment>
                </ImageListItem>
              ))}
            </ImageList>
          )}
        </Box>
      )}

      {showQuestion && (
        <RetroQuestion sx={{ padding: isSmall ? 1 : 2 }}>
          <Typography
            variant="body1"
            align="center"
            sx={{
              fontWeight: "500",
              lineHeight: 1.6,
              p: isSmall ? 1 : 2,
              backgroundColor: "rgba(0,0,0,0.2)",
              borderRadius: "4px",
              border: "1px dashed #ffab00",
            }}
          >
            {question}
          </Typography>
        </RetroQuestion>
      )}

      {showAnswer && (
        <RetroAnswer sx={{ padding: isSmall ? 1 : 2 }}>
          <Typography
            variant="body1"
            align="center"
            sx={{
              fontWeight: "500",
              lineHeight: 1.6,
              p: isSmall ? 1 : 2,
              backgroundColor: "rgba(0,0,0,0.2)",
              borderRadius: "4px",
              border: "1px dashed #00e676",
            }}
          >
            {answer}
          </Typography>
        </RetroAnswer>
      )}
    </Box>
  );
};

export default StandardQuestionDisplay;
