import { Navigate, Route, Routes } from "react-router-dom";
import LeagueOverview from "./LeagueOverview";
import GameBuilder from "./GameBuilder";
import { ControlCenterPage } from "./control-center/ControlCenter";
import { useEffect } from "react";
import { useSupabase } from "../auth/useSupabase";
import Player from "../player/Player";
import { Box, CircularProgress } from "@mui/material";

const AdminRoutes = () => {
  const { league, loading } = useSupabase();

  useEffect(() => {}, []);

  if (loading || !league) {
    return (
      <Box
        display="flex"
        width="100vw"
        height="100vh"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Routes>
      <Route path="/admin" element={<LeagueOverview />} />
      <Route path="/game/:gameId/builder" element={<GameBuilder />} />
      <Route path="/game/:gameId/admin" element={<ControlCenterPage />} />
      <Route path="/game/:gameId" element={<Player />} />
      <Route path="*" element={<Navigate to="/admin" />} />
    </Routes>
  );
};

export default AdminRoutes;
