import { Box, Typography, Paper, Button, TextField } from "@mui/material";
import { App } from "../../types/types";
import { useEffect, useState } from "react";
import { ControlCenterRoundContainer } from "./ControlCenterComponents";

interface ClassicBonusRoundAdminProps {
  bonusRound: App.ClassicBonusRoundBoard;
  onBonusRoundChange: (bonusRound: App.ClassicBonusRoundBoard) => void;
  currentRoundIdx: number | "bonus" | "overtime" | null;
  team1Id: string;
  team2Id: string;
  setCurrentRoundIdx: (n: number) => void;
}

const ClassicBonusRoundAdmin = (props: ClassicBonusRoundAdminProps) => {
  const {
    bonusRound: bonusRoundProp,
    onBonusRoundChange,
    team1Id,
    team2Id,
    currentRoundIdx,
    setCurrentRoundIdx,
  } = props;

  const [answerSearchQuery, setAnswerSearchQuery] = useState<string>("");

  const [bonusRound, setBonusRound] =
    useState<App.ClassicBonusRoundBoard>(bonusRoundProp);

  useEffect(() => {
    onBonusRoundChange(bonusRound);
  }, [bonusRound]);

  const onSelectAnswer = (idx: number) => {
    const updatedBonusRound = { ...bonusRound };
    const curAnswer = updatedBonusRound.answers[idx];
    const isTeamOneAnswer =
      bonusRound.team1Answers.length === bonusRound.team2Answers.length;
    if (curAnswer?.team_correct_id) {
      // shouldn't be able to do this!
    } else {
      if (isTeamOneAnswer) {
        updatedBonusRound.team1Answers.push(
          updatedBonusRound.answers[idx].text
        );
        updatedBonusRound.answers[idx].team_correct_id = team1Id;
      } else {
        updatedBonusRound.team2Answers.push(
          updatedBonusRound.answers[idx].text
        );
        updatedBonusRound.answers[idx].team_correct_id = team2Id;
      }
    }
    setBonusRound(updatedBonusRound);
  };

  const onIncorrectAnswer = () => {
    const updatedBonusRound = { ...bonusRound };
    if (bonusRound.team1Answers.length === bonusRound.team2Answers.length) {
      // its team 1s answer
      updatedBonusRound.team1Answers.push("");
    } else if (
      bonusRound.team1Answers.length ===
      bonusRound.team2Answers.length + 1
    ) {
      // team 2s answer
      updatedBonusRound.team2Answers.push("");
    }

    setBonusRound(updatedBonusRound);
  };

  const onUndo = () => {
    const isTeamOneAnswer =
      bonusRound.team1Answers.length === bonusRound.team2Answers.length;
    const updatedBonusRound = { ...bonusRound };
    let removedAnswerText: string | undefined;
    if (isTeamOneAnswer) {
      removedAnswerText = updatedBonusRound.team2Answers.pop();
    } else {
      removedAnswerText = updatedBonusRound.team1Answers.pop();
    }
    if (removedAnswerText) {
      const idxToSetNullCorrectTeam = updatedBonusRound.answers.findIndex(
        (a) => a.text === removedAnswerText
      );
      if (idxToSetNullCorrectTeam !== -1) {
        updatedBonusRound.answers[idxToSetNullCorrectTeam].team_correct_id =
          null;
      }
    }
    setBonusRound(updatedBonusRound);
  };

  const isTeam1Turn =
    bonusRound.team1Answers.length === bonusRound.team2Answers.length;

  return (
    <ControlCenterRoundContainer
      onClickNext={() => setCurrentRoundIdx(6)}
      onClickPrevious={() =>
        setCurrentRoundIdx(currentRoundIdx === "overtime" ? 11 : 5)
      }
      currentRoundIdx={currentRoundIdx}
    >
      <Box display="flex" width="100%" height="60px" mb="16px">
        <Box
          display="flex"
          flex={1}
          sx={{
            borderTopLeftRadius: "6px",
            borderBottomLeftRadius: "6px",
            backgroundColor: isTeam1Turn ? "blue" : "white",
            height: "60px",
            alignItems: "center",
            paddingLeft: "8px",
          }}
        >
          {isTeam1Turn && (
            <Typography fontSize="16px" fontWeight={700}>
              Team 1 Turn
            </Typography>
          )}
        </Box>
        <Box
          display="flex"
          flex={1}
          sx={{
            borderTopRightRadius: "6px",
            borderBottomRightRadius: "6px",
            backgroundColor: isTeam1Turn ? "white" : "blue",
            borderLeft: "1px solid black",
            height: "60px",
            alignItems: "center",
            justifyContent: "flex-end",
            paddingRight: "8px",
          }}
        >
          {!isTeam1Turn && (
            <Typography fontSize="16px" fontWeight={700}>
              Team 2 Turn
            </Typography>
          )}
        </Box>
      </Box>
      <Box display="flex" gap="12px" bgcolor="white" p="12px">
        <Box
          display="flex"
          flexDirection="column"
          gap="8px"
          flexBasis="20px"
          flexShrink={0}
        >
          {bonusRound.team1Answers?.map((a) => (
            <Box
              height="20px"
              width="20px"
              borderRadius="50%"
              bgcolor={a ? "green" : "red"}
            />
          ))}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="12px"
          alignItems="center"
          width="100%"
        >
          <TextField
            value={answerSearchQuery}
            onChange={(e) => setAnswerSearchQuery(e.target.value)}
            fullWidth
          />
          <Box
            sx={{
              width: "100%",
              overflow: "auto",
              padding: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                gap: `8px`,
                width: "100%",
              }}
            >
              {bonusRound.answers.map((item, index) => {
                if (
                  answerSearchQuery &&
                  !item.text.toLowerCase().includes(answerSearchQuery)
                ) {
                  return null;
                }

                return (
                  <Button
                    variant={item.team_correct_id ? "contained" : "outlined"}
                    onClick={() => {
                      onSelectAnswer(index);
                    }}
                    sx={{ height: "60px", width: "200px" }}
                    disabled={!!item.team_correct_id}
                    key={index}
                  >
                    {item.text}
                  </Button>
                );
              })}
            </Box>
          </Box>
          <Button
            fullWidth
            onClick={onIncorrectAnswer}
            size="large"
            variant="contained"
            color="error"
          >
            Incorrect Answer
          </Button>

          <Button
            fullWidth
            onClick={onUndo}
            size="large"
            variant="contained"
            color="error"
          >
            Undo
          </Button>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="8px"
          flexBasis="20px"
          flexShrink={0}
        >
          {bonusRound.team2Answers?.map((a) => (
            <Box
              height="20px"
              width="20px"
              borderRadius="50%"
              bgcolor={a ? "green" : "red"}
            />
          ))}
        </Box>
      </Box>
    </ControlCenterRoundContainer>
  );
};

export default ClassicBonusRoundAdmin;
