import {
  Box,
  Typography,
  Button,
  Paper,
  IconButton,
  TextField,
  Chip,
  Stack,
  Tooltip,
  Avatar,
  ButtonGroup,
} from "@mui/material";
import { App } from "../../types/types";
import useTimer from "./useTimer";
import { useEffect, useState } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import TimerIcon from "@mui/icons-material/Timer";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import { isNumber } from "lodash";
import { Link } from "react-router-dom";
import {
  getTeamScores,
  isCallAFriendActive,
  isDoubleSipActive,
  isDoubleUpActive,
  isLifelineUsed,
} from "../../utils/utils";
import { ControlCenterRoundContainer } from "./ControlCenterComponents";
import useScreenSize from "../../hooks/useScreenSize";

// display number, not index for the roundUsed
type LifelineDisplayData = {
  isActive: boolean;
  isDisabled: boolean;
  roundUsed: number | null;
};

type LifelineDisplay = {
  phoneAFriend: LifelineDisplayData;
  doubleUp: LifelineDisplayData;
  doubleSip: LifelineDisplayData;
};

interface StandardRoundProps {
  board: App.Board;
  onChangeBoard: (board: App.Board) => void;
  teamOneId: string;
  teamTwoId: string;
  teamOneName: string;
  teamTwoName: string;
  nicheCategory1: string;
  nicheCategory2: string;
  gameId: string;
  declareWinner: (winningTeamId: string | null) => void;
  winningTeamId: string | null;
}

const StandardRound = (props: StandardRoundProps) => {
  const {
    board: boardProp,
    onChangeBoard,
    teamOneId,
    teamTwoId,
    teamOneName,
    teamTwoName,
    nicheCategory1,
    nicheCategory2,
    gameId,
    declareWinner,
    winningTeamId,
  } = props;

  const { isSmall } = useScreenSize();

  const [board, setBoard] = useState<App.Board>(boardProp);

  const currentRoundIdx = board.currentRound.currentRoundIdx;

  useEffect(() => {
    onChangeBoard(board);
  }, [board]);

  const onTimerEnd = () => {};

  const { secondsLeft, setTime, clear, pause, isActive, start } =
    useTimer(onTimerEnd);

  const onChangeTimerInput = (n: number) => {
    setTime(n);
    pause();
  };

  if (!isNumber(currentRoundIdx)) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <Paper
          elevation={3}
          sx={{ p: 4, textAlign: "center", borderRadius: 2 }}
        >
          <SportsEsportsIcon
            sx={{ fontSize: 48, color: "primary.main", mb: 2 }}
          />
          <Typography variant="h5" fontWeight="medium">
            Loading Bonus Round...
          </Typography>
        </Paper>
      </Box>
    );
  }

  const currentRound = board.rounds[currentRoundIdx];
  const questionNumber = board.currentRound.question;

  const onAwardPoint = (team: 1 | 2) => {
    const correctAudio = document.getElementById(
      "correct-audio"
    ) as HTMLAudioElement;
    if (correctAudio) {
      correctAudio.play();
    }
    const updatedBoard = { ...board };
    if (questionNumber === 1) {
      updatedBoard.rounds[currentRoundIdx].question_1.correct_team_id =
        team === 1 ? teamOneId : teamTwoId;
    } else {
      updatedBoard.rounds[currentRoundIdx].question_2.correct_team_id =
        team === 1 ? teamOneId : teamTwoId;
    }
    updatedBoard.currentRound.showAnswer = true;

    // play ding?

    setBoard(updatedBoard);
  };

  const onUnawardPoint = (team: 1 | 2) => {
    const updatedBoard = { ...board };
    if (questionNumber === 1) {
      updatedBoard.rounds[currentRoundIdx].question_1.correct_team_id = null;
    } else {
      updatedBoard.rounds[currentRoundIdx].question_2.correct_team_id = null;
    }

    setBoard(updatedBoard);
  };

  const playBuzzer = () => {
    const buzzerAudio = document.getElementById(
      "buzzer-audio"
    ) as HTMLAudioElement;
    try {
      if (buzzerAudio) {
        buzzerAudio.play();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onChangeQuestion = (questionNum: 1 | 2) => {
    const updatedBoard = { ...board };
    updatedBoard.currentRound = {
      currentRoundIdx: updatedBoard.currentRound.currentRoundIdx,
      showAnswer: false,
      showQuestion: false,
      question: questionNum,
    };
    setBoard(updatedBoard);
  };

  const onClickLifeline = (teamOne: boolean, lifeline: App.Lifeline) => {
    const updatedBoard = { ...board };
    if (teamOne) {
      const isLifelineUsed =
        updatedBoard.rounds[currentRoundIdx].team_1_lifelines_used.includes(
          lifeline
        );
      if (isLifelineUsed) {
        updatedBoard.rounds[currentRoundIdx].team_1_lifelines_used =
          updatedBoard.rounds[currentRoundIdx].team_1_lifelines_used.filter(
            (l) => l !== lifeline
          );
      } else {
        updatedBoard.rounds[currentRoundIdx].team_1_lifelines_used = [
          ...updatedBoard.rounds[currentRoundIdx].team_1_lifelines_used,
          lifeline,
        ];
      }
    } else {
      const isLifelineUsed =
        updatedBoard.rounds[currentRoundIdx].team_2_lifelines_used.includes(
          lifeline
        );
      if (isLifelineUsed) {
        updatedBoard.rounds[currentRoundIdx].team_2_lifelines_used =
          updatedBoard.rounds[currentRoundIdx].team_2_lifelines_used.filter(
            (l) => l !== lifeline
          );
      } else {
        updatedBoard.rounds[currentRoundIdx].team_2_lifelines_used = [
          ...updatedBoard.rounds[currentRoundIdx].team_2_lifelines_used,
          lifeline,
        ];
      }
    }

    setBoard(updatedBoard);
  };

  const isLifelineThisRound = (teamOne: boolean, lifeline: App.Lifeline) => {
    if (teamOne) {
      return currentRound.team_1_lifelines_used.includes(lifeline);
    } else {
      return currentRound.team_2_lifelines_used.includes(lifeline);
    }
  };

  const canUseDoubleSip = (teamOne: boolean) => {
    if (isLifelineThisRound(teamOne, "Double Sip")) {
      return true;
    }
    if (isLifelineUsed(board, teamOne, "Double Sip")) {
      return false;
    }
    return true;
  };

  const canUseCallAFriend = (teamOne: boolean) => {
    if (isLifelineThisRound(teamOne, "Phone a Friend")) {
      return true;
    }
    if (isLifelineUsed(board, teamOne, "Phone a Friend")) {
      return false;
    }
    return true;
  };

  const canUseDoubleUp = (teamOne: boolean) => {
    if (isLifelineThisRound(teamOne, "Double up")) {
      return true;
    }
    if (isLifelineUsed(board, teamOne, "Double up")) {
      return false;
    }
    return true;
  };

  const isTeamCorrect = (teamOne: boolean) => {
    if (questionNumber === 1) {
      return (
        currentRound.question_1.correct_team_id ===
        (teamOne ? teamOneId : teamTwoId)
      );
    } else {
      return (
        currentRound.question_2.correct_team_id ===
        (teamOne ? teamOneId : teamTwoId)
      );
    }
  };

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const getRoundUsed = (teamOne: boolean, lifeline: App.Lifeline) => {
    const r = teamOne
      ? board.rounds.findIndex((r) =>
          r.team_1_lifelines_used.includes(lifeline)
        )
      : board.rounds.findIndex((r) =>
          r.team_2_lifelines_used.includes(lifeline)
        );
    return r === -1 ? null : r + 1;
  };

  const team1LifelineDisplayObj: LifelineDisplay = {
    phoneAFriend: {
      isActive: isCallAFriendActive(currentRound, true),
      isDisabled: !canUseCallAFriend(true),
      roundUsed: getRoundUsed(true, "Phone a Friend"),
    },
    doubleSip: {
      isActive: isDoubleSipActive(currentRound, true),
      isDisabled: !canUseDoubleSip(true),
      roundUsed: getRoundUsed(true, "Double Sip"),
    },
    doubleUp: {
      isActive: isDoubleUpActive(currentRound, true),
      isDisabled: !canUseDoubleUp(true),
      roundUsed: getRoundUsed(true, "Double up"),
    },
  };

  const team2LifelineDisplayObj: LifelineDisplay = {
    phoneAFriend: {
      isActive: isCallAFriendActive(currentRound, false),
      isDisabled: !canUseCallAFriend(false),
      roundUsed: getRoundUsed(false, "Phone a Friend"),
    },
    doubleSip: {
      isActive: isDoubleSipActive(currentRound, false),
      isDisabled: !canUseDoubleSip(false),
      roundUsed: getRoundUsed(false, "Double Sip"),
    },
    doubleUp: {
      isActive: isDoubleUpActive(currentRound, false),
      isDisabled: !canUseDoubleUp(false),
      roundUsed: getRoundUsed(false, "Double up"),
    },
  };

  const isTeam1Correct = isTeamCorrect(true);
  const isTeam2Correct = isTeamCorrect(false);

  const getCategoryTitle = () => {
    if (currentRoundIdx === 9) {
      return board.currentRound.question === 1
        ? nicheCategory1
        : nicheCategory2;
    }
    return currentRound.category;
  };

  const teamPoints = getTeamScores(board, teamOneId, teamTwoId);

  console.log(currentRoundIdx);

  return (
    <ControlCenterRoundContainer
      currentRoundIdx={currentRoundIdx}
      category={getCategoryTitle()}
      onClickPrevious={() => {
        const updatedBoard = { ...board };
        updatedBoard.currentRound = {
          currentRoundIdx:
            currentRoundIdx === 6 ? "bonus" : currentRoundIdx - 1,
          showAnswer: false,
          showQuestion: false,
          question: 1,
        };
        if (currentRoundIdx === 6) {
          onChangeBoard(updatedBoard);
        } else {
          setBoard(updatedBoard);
        }
      }}
      onClickNext={() => {
        const updatedBoard = { ...board };
        let nextRound: number | "bonus" | "overtime" | null;
        if (currentRoundIdx === 5) {
          nextRound = "bonus";
        } else if (currentRoundIdx === 11) {
          nextRound = "overtime";
        } else {
          nextRound = currentRoundIdx + 1;
        }
        updatedBoard.currentRound = {
          currentRoundIdx: nextRound,
          showAnswer: false,
          showQuestion: false,
          question: 1,
        };
        if (currentRoundIdx === 5) {
          onChangeBoard(updatedBoard);
        } else {
          setBoard(updatedBoard);
        }
      }}
    >
      <Box
        sx={{
          mx: "auto",
          px: 2,
          overflow: "auto",
          gap: "8px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        {/* Timer Controls */}
        <Paper
          elevation={2}
          sx={{
            py: 2,
            px: 4,
            mb: 1,
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              label="Timer (seconds)"
              type="number"
              value={secondsLeft}
              onChange={(e) => onChangeTimerInput(parseInt(e.target.value))}
              size="small"
              sx={{ width: 150, mr: 3 }}
            />
            <Paper
              elevation={3}
              sx={{
                px: 3,
                py: 2,
                mx: 3,
                borderRadius: 2,
                bgcolor: isActive ? "primary.main" : "background.paper",
                color: isActive ? "primary.contrastText" : "text.primary",
                transition: "all 0.3s ease",
              }}
            >
              <Typography
                variant="h4"
                sx={{ fontFamily: "monospace", fontWeight: "bold" }}
              >
                {formatTime(secondsLeft)}
              </Typography>
            </Paper>
            <ButtonGroup
              variant="contained"
              sx={{ ml: 2, gap: "32px", boxShadow: "none" }}
            >
              <Tooltip title={isActive ? "Pause Timer" : "Start Timer"}>
                <IconButton
                  onClick={isActive ? pause : start}
                  sx={{
                    bgcolor: isActive ? "warning.main" : "success.main",
                    color: "white",
                    "&:hover": {
                      bgcolor: isActive ? "warning.dark" : "success.dark",
                    },
                  }}
                  size="large"
                >
                  {isActive ? <PauseIcon /> : <PlayArrowIcon />}
                </IconButton>
              </Tooltip>
              <Tooltip title="Reset Timer">
                <IconButton
                  onClick={clear}
                  sx={{
                    bgcolor: "error.main",
                    color: "white",
                    "&:hover": {
                      bgcolor: "error.dark",
                    },
                  }}
                  size="large"
                >
                  <TimerIcon />
                </IconButton>
              </Tooltip>
            </ButtonGroup>
          </Box>
          <Box display="flex" width="100%" justifyContent="center" gap="12px">
            <Button
              onClick={() => {
                setTime(30);
                start();
              }}
              variant="outlined"
            >
              Start 30 seconds
            </Button>

            <Button
              onClick={() => {
                setTime(60);
                start();
              }}
              variant="outlined"
            >
              Start 60 seconds
            </Button>
          </Box>
        </Paper>

        {/* Question Selection */}
        <Paper
          elevation={2}
          sx={{
            py: 2,
            px: 4,
            mb: 1,
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            width: "600px",
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{ mb: 3 }}
            justifyContent="center"
          >
            <Button
              variant={questionNumber === 1 ? "contained" : "outlined"}
              onClick={() => onChangeQuestion(1)}
              size="large"
              sx={{ px: 4 }}
            >
              Question 1
            </Button>
            <Button
              variant={questionNumber === 2 ? "contained" : "outlined"}
              onClick={() => onChangeQuestion(2)}
              size="large"
              sx={{ px: 4 }}
            >
              Question 2
            </Button>
          </Stack>

          {/* Question and answer display */}
          <Stack spacing={2} alignItems="center">
            <Stack direction="row" spacing={2} justifyContent="center">
              <Button
                variant={
                  board.currentRound.showQuestion ? "contained" : "outlined"
                }
                onClick={() => {
                  const updatedBoard = { ...board };
                  updatedBoard.currentRound.showQuestion =
                    !updatedBoard.currentRound.showQuestion;
                  setBoard(updatedBoard);
                }}
                startIcon={
                  board.currentRound.showQuestion ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )
                }
                color="primary"
              >
                {board.currentRound.showQuestion
                  ? "Hide Question"
                  : "Show Question"}
              </Button>
              <Button
                variant={
                  board.currentRound.showAnswer ? "contained" : "outlined"
                }
                onClick={() => {
                  const updatedBoard = { ...board };
                  updatedBoard.currentRound.showAnswer =
                    !updatedBoard.currentRound.showAnswer;
                  setBoard(updatedBoard);
                }}
                startIcon={
                  board.currentRound.showAnswer ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )
                }
                color="secondary"
              >
                {board.currentRound.showAnswer ? "Hide Answer" : "Show Answer"}
              </Button>
            </Stack>

            {board.currentRound.showQuestion && (
              <Paper
                elevation={1}
                sx={{
                  p: 2,
                  bgcolor: "primary.light",
                  color: "primary.contrastText",
                  width: "100%",
                  borderRadius: 2,
                }}
              >
                <Typography
                  variant="body1"
                  textAlign="center"
                  fontWeight="medium"
                >
                  {board.currentRound.question === 1
                    ? currentRound.question_1.question
                    : currentRound.question_2.question}
                </Typography>
              </Paper>
            )}

            {board.currentRound.showQuestion && (
              <Paper
                elevation={1}
                sx={{
                  p: 2,
                  bgcolor: board.currentRound.showAnswer
                    ? "secondary.light"
                    : "white",
                  color: "secondary.contrastText",
                  width: "100%",
                  borderRadius: 2,
                  border: "1px solid secondary.light",
                }}
              >
                <Typography
                  variant="body1"
                  textAlign="center"
                  fontWeight="medium"
                  color={
                    board.currentRound.showAnswer ? "white" : "secondary.light"
                  }
                >
                  {board.currentRound.question === 1
                    ? currentRound.question_1.answer
                    : currentRound.question_2.answer}
                </Typography>
              </Paper>
            )}
          </Stack>
        </Paper>

        {/* Team Sections */}
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="center"
          flexWrap="wrap"
        >
          {/* Team One - Left Side */}
          <Box display="flex">
            <Paper
              elevation={3}
              sx={{
                p: 2,
                height: "100%",
                borderRadius: 2,
                borderLeft: "6px solid #1976d2",
                transition: "transform 0.2s",
                width: "420px",
                "&:hover": {
                  transform: "translateY(-4px)",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 1,
                  flexWrap: "wrap",
                }}
              >
                <Avatar
                  sx={{
                    bgcolor: "primary.main",
                    width: 60,
                    height: 60,
                    boxShadow: 3,
                  }}
                >
                  <Typography variant="h4">{teamPoints.team1}</Typography>
                </Avatar>
                <Typography variant="h5" fontWeight="bold" color="primary">
                  {teamOneName}
                </Typography>
              </Box>

              {/* Correct/Incorrect Controls */}
              <Box sx={{ display: "flex", justifyContent: "center", my: 1 }}>
                <Button
                  variant={isTeam1Correct ? "contained" : "outlined"}
                  color="success"
                  startIcon={<CheckCircleIcon />}
                  onClick={() =>
                    isTeam1Correct ? onUnawardPoint(1) : onAwardPoint(1)
                  }
                  size="large"
                  fullWidth
                  sx={{ px: 4, py: 1 }}
                >
                  Correct
                </Button>
              </Box>

              {/* Lifelines */}
              <Box sx={{ mt: 1 }}>
                <Stack spacing={1} direction="row" flexWrap="wrap">
                  <Button
                    variant={
                      team1LifelineDisplayObj.doubleSip.isActive
                        ? "contained"
                        : "outlined"
                    }
                    color="primary"
                    disabled={team1LifelineDisplayObj.doubleSip.isDisabled}
                    onClick={() => onClickLifeline(true, "Double Sip")}
                    sx={{
                      py: 1,
                      opacity: team1LifelineDisplayObj.doubleSip.isDisabled
                        ? 0.7
                        : 1,
                    }}
                  >
                    Double Sip
                    {team1LifelineDisplayObj.doubleSip.roundUsed !== null && (
                      <Chip
                        label={team1LifelineDisplayObj.doubleSip.roundUsed}
                        size="small"
                        color="secondary"
                        sx={{ ml: 1 }}
                      />
                    )}
                  </Button>
                  <Button
                    variant={
                      team1LifelineDisplayObj.phoneAFriend.isActive
                        ? "contained"
                        : "outlined"
                    }
                    color="primary"
                    disabled={team1LifelineDisplayObj.phoneAFriend.isDisabled}
                    onClick={() => onClickLifeline(true, "Phone a Friend")}
                    sx={{
                      py: 1,
                      opacity: team1LifelineDisplayObj.phoneAFriend.isDisabled
                        ? 0.7
                        : 1,
                    }}
                  >
                    Phone a Friend
                    {team1LifelineDisplayObj.phoneAFriend.roundUsed !==
                      null && (
                      <Chip
                        label={team1LifelineDisplayObj.phoneAFriend.roundUsed}
                        size="small"
                        color="secondary"
                        sx={{ ml: 1 }}
                      />
                    )}
                  </Button>
                  <Button
                    variant={
                      team1LifelineDisplayObj.doubleUp.isActive
                        ? "contained"
                        : "outlined"
                    }
                    color="primary"
                    disabled={team1LifelineDisplayObj.doubleUp.isDisabled}
                    onClick={() => onClickLifeline(true, "Double up")}
                    sx={{
                      py: 1,
                      opacity: team1LifelineDisplayObj.doubleUp.isDisabled
                        ? 0.7
                        : 1,
                    }}
                  >
                    Double Up
                    {team1LifelineDisplayObj.doubleUp.roundUsed !== null && (
                      <Chip
                        label={team1LifelineDisplayObj.doubleUp.roundUsed}
                        size="small"
                        color="secondary"
                        sx={{ ml: 1 }}
                      />
                    )}
                  </Button>
                </Stack>
              </Box>
            </Paper>
          </Box>

          {/* Center - Buzzer */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Paper
                elevation={5}
                sx={{
                  p: 2,
                  borderRadius: "50%",
                  bgcolor: "#f5f5f5",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  color="error"
                  onClick={playBuzzer}
                  sx={{
                    width: 80,
                    height: 80,
                    border: "5px solid #f44336",
                    borderRadius: "50%",
                    transition: "all 0.2s",
                    "&:hover": {
                      transform: "scale(1.1)",
                      bgcolor: "#ffebee",
                    },
                  }}
                >
                  <VolumeUpIcon sx={{ fontSize: 40 }} />
                </IconButton>
              </Paper>
              <Typography
                variant="subtitle2"
                color="error.main"
                fontWeight="medium"
              >
                BUZZER
              </Typography>
            </Box>
          </Box>

          {/* Team Two - Right Side */}
          <Box display="flex">
            <Paper
              elevation={3}
              sx={{
                p: 2,
                height: "100%",
                borderRadius: 2,
                borderRight: "6px solid #dc004e",
                transition: "transform 0.2s",
                width: "420px",
                "&:hover": {
                  transform: "translateY(-4px)",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 1,
                  flexWrap: "wrap",
                }}
              >
                <Typography variant="h5" fontWeight="bold" color="secondary">
                  {teamTwoName}
                </Typography>
                <Avatar
                  sx={{
                    bgcolor: "secondary.main",
                    width: 60,
                    height: 60,
                    boxShadow: 3,
                  }}
                >
                  <Typography variant="h4">{teamPoints.team2}</Typography>
                </Avatar>
              </Box>

              {/* Correct/Incorrect Controls */}
              <Box sx={{ display: "flex", justifyContent: "center", my: 1 }}>
                <Button
                  variant={isTeam2Correct ? "contained" : "outlined"}
                  color="success"
                  startIcon={<CheckCircleIcon />}
                  onClick={() =>
                    isTeam2Correct ? onUnawardPoint(2) : onAwardPoint(2)
                  }
                  size="large"
                  sx={{ px: 4, py: 1 }}
                  fullWidth
                >
                  Correct
                </Button>
              </Box>

              {/* Lifelines */}
              <Box sx={{ mt: 1, display: "flex", justifyContent: "flex-end" }}>
                <Stack spacing={1} direction="row" flexWrap="wrap">
                  <Button
                    variant={
                      team2LifelineDisplayObj.doubleSip.isActive
                        ? "contained"
                        : "outlined"
                    }
                    color="secondary"
                    disabled={team2LifelineDisplayObj.doubleSip.isDisabled}
                    onClick={() => onClickLifeline(false, "Double Sip")}
                    sx={{
                      py: 1,
                      opacity: team2LifelineDisplayObj.doubleSip.isDisabled
                        ? 0.7
                        : 1,
                    }}
                  >
                    Double Sip
                    {team2LifelineDisplayObj.doubleSip.roundUsed !== null && (
                      <Chip
                        label={team2LifelineDisplayObj.doubleSip.roundUsed}
                        size="small"
                        color="primary"
                        sx={{ ml: 1 }}
                      />
                    )}
                  </Button>
                  <Button
                    variant={
                      team2LifelineDisplayObj.phoneAFriend.isActive
                        ? "contained"
                        : "outlined"
                    }
                    color="secondary"
                    disabled={team2LifelineDisplayObj.phoneAFriend.isDisabled}
                    onClick={() => onClickLifeline(false, "Phone a Friend")}
                    sx={{
                      py: 1,
                      opacity: team2LifelineDisplayObj.phoneAFriend.isDisabled
                        ? 0.7
                        : 1,
                    }}
                  >
                    Phone a Friend
                    {team2LifelineDisplayObj.phoneAFriend.roundUsed !==
                      null && (
                      <Chip
                        label={team2LifelineDisplayObj.phoneAFriend.roundUsed}
                        size="small"
                        color="primary"
                        sx={{ ml: 1 }}
                      />
                    )}
                  </Button>
                  <Button
                    variant={
                      team2LifelineDisplayObj.doubleUp.isActive
                        ? "contained"
                        : "outlined"
                    }
                    color="secondary"
                    disabled={team2LifelineDisplayObj.doubleUp.isDisabled}
                    onClick={() => onClickLifeline(false, "Double up")}
                    sx={{
                      py: 1,
                      opacity: team2LifelineDisplayObj.doubleUp.isDisabled
                        ? 0.7
                        : 1,
                    }}
                  >
                    Double Up
                    {team2LifelineDisplayObj.doubleUp.roundUsed !== null && (
                      <Chip
                        label={team2LifelineDisplayObj.doubleUp.roundUsed}
                        size="small"
                        color="primary"
                        sx={{ ml: 1 }}
                      />
                    )}
                  </Button>
                </Stack>
              </Box>
            </Paper>
          </Box>
        </Box>
        <Paper sx={{ width: "100%", mb: isSmall ? "24px" : undefined }}>
          <Link to={`/game/${gameId}`} target="_blank">
            <Button variant="contained" fullWidth>
              Open Participant View
            </Button>
          </Link>
        </Paper>
        {currentRoundIdx === 11 && (
          <Paper sx={{ width: "100%" }}>
            <Button
              variant="contained"
              fullWidth
              disabled={teamPoints.team1 === teamPoints.team2 && !winningTeamId}
              onClick={() => {
                if (winningTeamId) {
                  declareWinner(null);
                } else {
                  declareWinner(
                    teamPoints.team1 > teamPoints.team2 ? teamOneId : teamTwoId
                  );
                }
              }}
            >
              {`${winningTeamId ? "Un-" : ""}Declare ${
                teamPoints.team1 > teamPoints.team2 ? teamOneName : teamTwoName
              } Winner`}
            </Button>
          </Paper>
        )}
      </Box>
      <audio src="/sounds/wrong-answer.mp3" id="buzzer-audio" />
      <audio src="/sounds/correct-answer.mp3" id="correct-audio" />
    </ControlCenterRoundContainer>
  );
};

export default StandardRound;
