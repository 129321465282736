import { App } from "../types/types";
import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Button,
  CircularProgress,
  Grid,
  Chip,
  Alert,
  Snackbar,
  ImageList,
  ImageListItem,
  Paper,
  Stack,
  useTheme,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import {
  Save,
  CloudUpload,
  Delete,
  CategoryOutlined,
  QuizOutlined,
  CheckCircleOutline,
  HelpOutline,
  ImageOutlined,
  PlayCircleOutline,
  CheckCircle,
  WarningAmber,
  AccessTime,
} from "@mui/icons-material";
import { debounce } from "lodash";
import useRoundAttachments from "./useRoundAttachments";

interface RoundEditorProps {
  handleSaveRound: (
    roundIdx: number,
    round: App.Round,
    team1Niche?: string,
    team2Niche?: string
  ) => void;
  roundIdx: number;
  round: App.Round;
  isEditing: boolean;
  isNiche: boolean;
  team1NicheCategory?: string;
  team2NicheCategory?: string;
  disabled: boolean;
}

// Helper to render the round status as text and icon
const renderRoundStatus = (
  status: number
): { text: string; icon: React.ReactNode; color: string } => {
  switch (status) {
    case 0:
      return {
        text: "Not Started",
        icon: <AccessTime />,
        color: "#9e9e9e", // gray
      };
    case 1:
      return {
        text: "Team 1 Answering",
        icon: <PlayCircleOutline />,
        color: "#913175", // primary
      };
    case 2:
      return {
        text: "Team 2 Answering",
        icon: <PlayCircleOutline />,
        color: "#CD5888", // secondary
      };
    case 3:
      return {
        text: "Complete",
        icon: <CheckCircle />,
        color: "#4caf50", // success
      };
    default:
      return {
        text: "Unknown",
        icon: <WarningAmber />,
        color: "#f44336", // error
      };
  }
};

const RoundEditor = (props: RoundEditorProps) => {
  const {
    isNiche,
    handleSaveRound,
    round: roundProp,
    roundIdx,
    isEditing,
    team1NicheCategory: initialTeam1Niche,
    team2NicheCategory: initialTeam2Niche,
    disabled,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [round, setRound] = useState<App.Round>(roundProp);
  const [team1NicheCategory, setTeam1NicheCategory] = useState<
    string | undefined
  >(initialTeam1Niche);
  const [team2NicheCategory, setTeam2NicheCategory] = useState<
    string | undefined
  >(initialTeam2Niche);
  const [saveAnimation, setSaveAnimation] = useState(false);

  const {
    handleDeleteAttachment,
    setNotification,
    notification,
    loadAttachmentUrls,
    attachments1,
    attachments2,
    handleFileUpload,
    isUploading1,
    isUploading2,
  } = useRoundAttachments({ round, setRound });

  useEffect(() => {
    debouncedSaveRound(
      roundIdx,
      round,
      isNiche ? team1NicheCategory : undefined,
      isNiche ? team2NicheCategory : undefined
    );
  }, [round, team1NicheCategory, team2NicheCategory]);

  useEffect(() => {
    // Load attachment URLs when component mounts or round changes
    loadAttachmentUrls();
  }, [roundProp]);

  const debouncedSaveRound = useCallback(
    debounce(
      async (
        roundIdx: number,
        round: App.Round,
        team1Niche?: string,
        team2Niche?: string
      ) => {
        handleSaveRound(roundIdx, round, team1Niche, team2Niche);
      },
      400
    ),
    []
  );

  if (roundIdx === undefined) {
    return null;
  }

  const onSaveRound = () => {
    if (isNiche) {
      handleSaveRound(roundIdx, round, team1NicheCategory, team2NicheCategory);
    } else {
      handleSaveRound(roundIdx, round);
    }
    // Show save animation
    setSaveAnimation(true);
    setTimeout(() => setSaveAnimation(false), 1000);
  };

  // Handle notification close
  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  // Get round status info
  const statusInfo = renderRoundStatus(round.status);

  return (
    <Box
      p="8px 0px"
      // height="100%"
      overflow="auto"
      display="flex"
      flex={1}
      width="100%"
    >
      <Card
        variant="outlined"
        sx={{
          width: "100%",
          borderRadius: 2,
          boxShadow: 2,
          transition: "box-shadow 0.3s ease",
          display: "flex",
          flex: 1,
          flexDirection: "column",
          "&:hover": {
            boxShadow: 4,
          },
        }}
      >
        <CardHeader
          title={
            <Typography
              variant="h5"
              component="h2"
              fontWeight="bold"
              color="primary"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                fontSize: { xs: "1.2rem", sm: "1.5rem" },
              }}
            >
              {isNiche ? <CategoryOutlined /> : <QuizOutlined />}
              {isNiche
                ? `Niche Category Round (${round.category || "Unnamed"})`
                : `Round ${roundIdx + 1}: ${round.category || "Unnamed"}`}
            </Typography>
          }
          action={
            <Tooltip title="Save Round">
              <IconButton
                onClick={onSaveRound}
                color="primary"
                sx={{
                  transition: "transform 0.2s",
                  transform: saveAnimation ? "scale(1.2)" : "scale(1)",
                  bgcolor: saveAnimation
                    ? "rgba(145, 49, 117, 0.1)"
                    : "transparent",
                }}
                disabled={disabled}
              >
                <Save />
              </IconButton>
            </Tooltip>
          }
          sx={{
            borderBottom: `1px solid ${theme.palette.divider}`,
            bgcolor: "background.default",
            p: { xs: 2, sm: 3 },
          }}
        />
        <CardContent
          sx={{
            p: { xs: 2, sm: 3 },
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              p: 1,
              mb: 3,
              display: "flex",
              alignItems: "center",
              borderRadius: 1,
              bgcolor: "background.default",
              border: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                color: statusInfo.color,
              }}
            >
              {statusInfo.icon}
              <Typography variant="subtitle1" fontWeight="medium">
                Status: {statusInfo.text}
              </Typography>
            </Box>
          </Box>

          {isNiche &&
          initialTeam1Niche !== undefined &&
          initialTeam2Niche !== undefined ? (
            <Grid container spacing={2} sx={{ mb: 3 }}>
              <Grid spacing={{ xs: 12, md: 6 }}>
                <TextField
                  label="Team 1 Niche Category"
                  onChange={(e) => setTeam1NicheCategory(e.target.value)}
                  value={team1NicheCategory}
                  fullWidth
                  disabled={disabled}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <CategoryOutlined color="primary" sx={{ mr: 1 }} />
                    ),
                  }}
                />
              </Grid>
              <Grid spacing={{ xs: 12, md: 6 }}>
                <TextField
                  label="Team 2 Niche Category"
                  onChange={(e) => setTeam2NicheCategory(e.target.value)}
                  value={team2NicheCategory}
                  fullWidth
                  disabled={disabled}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <CategoryOutlined color="secondary" sx={{ mr: 1 }} />
                    ),
                  }}
                />
              </Grid>
            </Grid>
          ) : (
            <TextField
              label="Category"
              value={round.category}
              onChange={(e) => setRound({ ...round, category: e.target.value })}
              fullWidth
              disabled={disabled}
              margin="normal"
              variant="outlined"
              required
              InputProps={{
                startAdornment: (
                  <CategoryOutlined color="primary" sx={{ mr: 1 }} />
                ),
              }}
              sx={{ mb: 3 }}
            />
          )}

          {/* Question 1 */}
          <Paper
            elevation={1}
            sx={{
              p: { xs: 2, sm: 3 },
              mt: 3,
              mb: 4,
              borderRadius: 2,
              border: `1px solid ${theme.palette.primary.light}`,
              borderLeft: `4px solid ${theme.palette.primary.main}`,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                mb: 2,
                display: "flex",
                alignItems: "center",
                gap: 1,
                color: "primary.main",
                fontWeight: "bold",
              }}
            >
              <HelpOutline />
              Question 1
            </Typography>

            <TextField
              label="Question"
              value={round.question_1.question}
              onChange={(e) =>
                setRound({
                  ...round,
                  question_1: {
                    ...round.question_1,
                    question: e.target.value,
                  },
                })
              }
              fullWidth
              multiline
              disabled={disabled}
              rows={2}
              margin="normal"
              variant="outlined"
              required
            />

            <TextField
              label="Answer"
              value={round.question_1.answer}
              onChange={(e) =>
                setRound({
                  ...round,
                  question_1: {
                    ...round.question_1,
                    answer: e.target.value,
                  },
                })
              }
              fullWidth
              disabled={disabled}
              margin="normal"
              variant="outlined"
              required
              InputProps={{
                startAdornment: (
                  <CheckCircleOutline color="success" sx={{ mr: 1 }} />
                ),
              }}
            />

            {/* Question 1 Attachments */}
            <Box sx={{ mt: 3 }}>
              <Typography
                variant="subtitle1"
                sx={{
                  mb: 1.5,
                  fontWeight: "medium",
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <ImageOutlined />
                Attachments{" "}
                {attachments1.length > 0 && `(${attachments1.length})`}
              </Typography>

              {/* Display existing attachments */}
              <Stack direction="row" flexWrap="wrap" gap={1} mb={2}>
                {attachments1.map((attachment) => (
                  <Chip
                    key={attachment.id}
                    label={attachment.filename}
                    onDelete={() => handleDeleteAttachment(attachment.id, 1)}
                    deleteIcon={<Delete />}
                    variant="outlined"
                    color="primary"
                    sx={{ mb: 1 }}
                    disabled={disabled}
                  />
                ))}
              </Stack>

              {/* Image previews */}
              {attachments1.length > 0 && (
                <Paper variant="outlined" sx={{ p: 1, mb: 2, borderRadius: 2 }}>
                  <ImageList
                    sx={{
                      width: "100%",
                      height: "fit-content",
                      maxHeight: 300,
                      overflowY: "auto",
                    }}
                    cols={isMobile ? 2 : isTablet ? 3 : 4}
                    rowHeight={164}
                    gap={8}
                  >
                    {attachments1.map((attachment) => (
                      <ImageListItem key={attachment.id}>
                        <img
                          src={attachment.url}
                          alt={attachment.filename}
                          style={{
                            objectFit: "contain",
                            height: "100%",
                            width: "100%",
                            borderRadius: 4,
                          }}
                          loading="lazy"
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </Paper>
              )}

              {/* Upload button */}
              <Button
                component="label"
                variant="outlined"
                color="primary"
                startIcon={
                  isUploading1 ? (
                    <CircularProgress size={20} />
                  ) : (
                    <CloudUpload />
                  )
                }
                disabled={isUploading1 || disabled}
                sx={{ mt: 1 }}
                fullWidth={isMobile}
              >
                {isUploading1 ? "Uploading..." : "Upload Image"}
                <input
                  type="file"
                  accept="image/*,.heic,.heif"
                  hidden
                  multiple
                  onChange={(e) => handleFileUpload(e, 1)}
                />
              </Button>
            </Box>
          </Paper>

          {/* Question 2 */}
          <Paper
            elevation={1}
            sx={{
              p: { xs: 2, sm: 3 },
              mt: 3,
              mb: 2,
              borderRadius: 2,
              border: `1px solid ${theme.palette.secondary.light}`,
              borderLeft: `4px solid ${theme.palette.secondary.main}`,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                mb: 2,
                display: "flex",
                alignItems: "center",
                gap: 1,
                color: "secondary.main",
                fontWeight: "bold",
              }}
            >
              <HelpOutline />
              Question 2
            </Typography>

            <TextField
              label="Question"
              value={round.question_2.question}
              onChange={(e) =>
                setRound({
                  ...round,
                  question_2: {
                    ...round.question_2,
                    question: e.target.value,
                  },
                })
              }
              fullWidth
              disabled={disabled}
              multiline
              rows={2}
              margin="normal"
              variant="outlined"
              required
            />

            <TextField
              label="Answer"
              value={round.question_2.answer}
              disabled={disabled}
              onChange={(e) =>
                setRound({
                  ...round,
                  question_2: {
                    ...round.question_2,
                    answer: e.target.value,
                  },
                })
              }
              fullWidth
              margin="normal"
              variant="outlined"
              required
              InputProps={{
                startAdornment: (
                  <CheckCircleOutline color="success" sx={{ mr: 1 }} />
                ),
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onSaveRound();
                }
              }}
            />

            {/* Question 2 Attachments */}
            <Box sx={{ mt: 3 }}>
              <Typography
                variant="subtitle1"
                sx={{
                  mb: 1.5,
                  fontWeight: "medium",
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <ImageOutlined />
                Attachments{" "}
                {attachments2.length > 0 && `(${attachments2.length})`}
              </Typography>

              {/* Display existing attachments */}
              <Stack direction="row" flexWrap="wrap" gap={1} mb={2}>
                {attachments2.map((attachment) => (
                  <Chip
                    key={attachment.id}
                    label={attachment.filename}
                    onDelete={() => handleDeleteAttachment(attachment.id, 2)}
                    deleteIcon={<Delete />}
                    variant="outlined"
                    color="secondary"
                    sx={{ mb: 1 }}
                    disabled={disabled}
                  />
                ))}
              </Stack>

              {/* Image previews */}
              {attachments2.length > 0 && (
                <Paper variant="outlined" sx={{ p: 1, mb: 2, borderRadius: 2 }}>
                  <ImageList
                    sx={{
                      width: "100%",
                      height: "fit-content",
                      maxHeight: 300,
                      overflowY: "auto",
                    }}
                    cols={isMobile ? 2 : isTablet ? 3 : 4}
                    rowHeight={164}
                    gap={8}
                  >
                    {attachments2.map((attachment) => (
                      <ImageListItem key={attachment.id}>
                        <img
                          src={attachment.url}
                          alt={attachment.filename}
                          style={{
                            objectFit: "contain",
                            height: "100%",
                            width: "100%",
                            borderRadius: 4,
                          }}
                          loading="lazy"
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </Paper>
              )}

              {/* Upload button */}
              <Button
                component="label"
                variant="outlined"
                color="secondary"
                startIcon={
                  isUploading2 ? (
                    <CircularProgress size={20} />
                  ) : (
                    <CloudUpload />
                  )
                }
                disabled={isUploading2 || disabled}
                sx={{ mt: 1 }}
                fullWidth={isMobile}
              >
                {isUploading2 ? "Uploading..." : "Upload Image"}
                <input
                  type="file"
                  accept="image/*,.heic,.heif"
                  hidden
                  multiple
                  onChange={(e) => handleFileUpload(e, 2)}
                />
              </Button>
            </Box>
          </Paper>

          {/* Save Button - For Mobile Users */}
          {isMobile && (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={disabled}
              startIcon={<Save />}
              onClick={onSaveRound}
              sx={{ mt: 3 }}
            >
              Save Round
            </Button>
          )}
        </CardContent>
      </Card>

      {/* Notification Snackbar */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notification.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default RoundEditor;
