import {
  Tables,
  DozenSupabaseClient,
  Json,
  TablesInsert,
} from "../../database.types";
import { App } from "../types/types";

// Game CRUD operations
export const gameService = {
  async getAll(supabase: DozenSupabaseClient): Promise<Tables<"games">[]> {
    const { data, error } = await supabase
      .from("games")
      .select("*")
      .order("created_at", { ascending: false });

    if (error) {
      console.error("Error fetching games:", error);
      throw error;
    }

    return data || [];
  },

  async getByLeague(
    supabase: DozenSupabaseClient,
    leagueId: string
  ): Promise<Tables<"games">[]> {
    const { data, error } = await supabase
      .from("games")
      .select("*")
      .eq("league_id", leagueId)
      .order("created_at", { ascending: false });

    if (error) {
      console.error(`Error fetching games for league ${leagueId}:`, error);
      throw error;
    }

    return data || [];
  },

  async getById(
    supabase: DozenSupabaseClient,
    id: string
  ): Promise<Tables<"games"> | null> {
    const { data, error } = await supabase
      .from("games")
      .select("*")
      .eq("id", id)
      .single();

    if (error) {
      console.error(`Error fetching game with id ${id}:`, error);
      throw error;
    }

    return data;
  },

  async create(
    supabase: DozenSupabaseClient,
    game: TablesInsert<"games">
  ): Promise<Tables<"games">> {
    const { data, error } = await supabase
      .from("games")
      .insert(game)
      .select()
      .single();

    if (error) {
      console.error("Error creating game:", error);
      throw error;
    }

    return data;
  },

  async update(
    supabase: DozenSupabaseClient,
    id: string,
    updates: Partial<Omit<Tables<"games">, "id" | "created_at" | "updated_at">>
  ): Promise<Tables<"games">> {
    const { data, error } = await supabase
      .from("games")
      .update(updates)
      .eq("id", id)
      .select()
      .single();

    if (error) {
      console.error(`Error updating game with id ${id}:`, error);
      throw error;
    }

    return data;
  },

  async delete(supabase: DozenSupabaseClient, id: string): Promise<void> {
    const { error } = await supabase.from("games").delete().eq("id", id);

    if (error) {
      console.error(`Error deleting game with id ${id}:`, error);
      throw error;
    }
  },

  async upsertGameBoard(
    supabase: DozenSupabaseClient,
    board_id: string,
    board: App.Board | null
  ) {
    const { error, data } = await supabase
      .from("boards")
      .upsert({
        id: board_id,
        board: board as unknown as Json,
      })
      // .eq("id", board_id)
      .select();

    if (error) {
      console.error(error);
      throw error;
    }
    return data;
  },

  async getGameBoard(supabase: DozenSupabaseClient, board_id: string) {
    const { error, data } = await supabase
      .from("boards")
      .select("*")
      .eq("id", board_id)
      .single();

    if (error) {
      console.error(error);
      throw error;
    }
    return data;
  },
};
