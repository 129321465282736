import { useState } from "react";
import { useSupabase } from "../auth/useSupabase";
import { App } from "../types/types";
import heic2any from "heic2any";

// Interface for attachment data including URL for display
interface AttachmentWithUrl {
  id: string;
  url: string;
  filename: string;
}

const useRoundAttachments = (args: {
  round: App.Round;
  setRound: (round: App.Round) => void;
}) => {
  const { round, setRound } = args;
  const { supabase } = useSupabase();

  // Loading states
  const [isUploading1, setIsUploading1] = useState<boolean>(false);
  const [isUploading2, setIsUploading2] = useState<boolean>(false);

  // For storing image attachments with their URLs for display
  const [attachments1, setAttachments1] = useState<AttachmentWithUrl[]>([]);
  const [attachments2, setAttachments2] = useState<AttachmentWithUrl[]>([]);

  // Notification state
  const [notification, setNotification] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error" | "info" | "warning";
  }>({
    open: false,
    message: "",
    severity: "info",
  });

  // Helper function to check if file is a HEIC image
  const isHeicImage = (file: File): boolean => {
    return (
      file.type === "image/heic" ||
      file.type === "image/heif" ||
      file.name.toLowerCase().endsWith(".heic") ||
      file.name.toLowerCase().endsWith(".heif")
    );
  };

  const question1AttachmentIds = round.question_1.attachment_ids || [];
  const question2AttachmentIds = round.question_2.attachment_ids || [];

  // Helper function to convert HEIC to JPEG
  const convertHeicToJpeg = async (file: File): Promise<File> => {
    try {
      // Convert HEIC to Blob
      const jpegBlob = (await heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 0.8,
      })) as Blob;

      // Original filename without extension
      const originalName = file.name.substring(0, file.name.lastIndexOf("."));

      // Create a new File object from the Blob
      return new File([jpegBlob], `${originalName}.jpg`, {
        type: "image/jpeg",
      });
    } catch (error) {
      console.error("HEIC conversion error:", error);
      throw new Error(`Failed to convert HEIC file: ${file.name}`);
    }
  };

  const loadAttachmentUrls = async () => {
    const loadUrls = async (
      attachmentIds: string[]
    ): Promise<AttachmentWithUrl[]> => {
      if (!attachmentIds || attachmentIds.length === 0) return [];

      return Promise.all(
        attachmentIds.map(async (id) => {
          const { data, error } = await supabase.storage
            .from("dozen-attachments")
            .createSignedUrl(`attachments/${id}`, 3600); // 1 hour expiry

          if (error) {
            console.error("Error getting signed URL:", error);
            return { id, url: "", filename: id };
          }

          return {
            id,
            url: data?.signedUrl || "",
            filename: id.length > 10 ? `${id.substring(0, 10)}...` : id,
          };
        })
      );
    };

    const attachments1 = await loadUrls(question1AttachmentIds);
    const attachments2 = await loadUrls(question2AttachmentIds);

    setAttachments1(attachments1);
    setAttachments2(attachments2);
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    questionNumber: 1 | 2
  ) => {
    const files = event.target.files;
    if (!files || files.length === 0) return;

    // Set uploading state for the appropriate question
    if (questionNumber === 1) {
      setIsUploading1(true);
    } else {
      setIsUploading2(true);
    }

    try {
      const uploadedIds: string[] = [];
      const filesArray = Array.from(files);

      // Process each file sequentially
      for (let i = 0; i < filesArray.length; i++) {
        let file = filesArray[i];
        let processedFile = file;

        // Convert HEIC files to JPEG
        if (isHeicImage(file)) {
          try {
            setNotification({
              open: true,
              message: `Converting ${file.name} from HEIC to JPEG...`,
              severity: "info",
            });

            processedFile = await convertHeicToJpeg(file);

            setNotification({
              open: true,
              message: `Successfully converted ${file.name} to JPEG`,
              severity: "success",
            });
          } catch (error) {
            setNotification({
              open: true,
              message: `Failed to convert HEIC file: ${file.name}`,
              severity: "error",
            });
            continue; // Skip this file and move to the next one
          }
        }

        // Create a unique filename
        const fileExt =
          processedFile.name.split(".").pop()?.toLowerCase() || "jpg";
        const fileName = `${Date.now()}-${Math.random()
          .toString(36)
          .substring(2, 15)}.${fileExt}`;
        const filePath = `attachments/${fileName}`;

        // Upload the file to Supabase
        const { data, error } = await supabase.storage
          .from("dozen-attachments")
          .upload(filePath, processedFile);

        if (error) {
          console.error("Error uploading file:", error);
          setNotification({
            open: true,
            message: `Error uploading ${processedFile.name}: ${error.message}`,
            severity: "error",
          });
        } else {
          uploadedIds.push(fileName);
        }
      }

      // Update the round state with new attachment IDs
      if (uploadedIds.length > 0) {
        if (questionNumber === 1) {
          const updatedRound = {
            ...round,
            question_1: {
              ...round.question_1,
              attachment_ids: [
                ...(round.question_1.attachment_ids || []),
                ...uploadedIds,
              ],
            },
          };
          setRound(updatedRound);
        } else {
          const updatedRound = {
            ...round,
            question_2: {
              ...round.question_2,
              attachment_ids: [
                ...(round.question_2.attachment_ids || []),
                ...uploadedIds,
              ],
            },
          };
          setRound(updatedRound);
        }

        // Reload attachment URLs after upload
        await loadAttachmentUrls();

        // Show success notification
        if (uploadedIds.length === 1) {
          setNotification({
            open: true,
            message: "1 file uploaded successfully",
            severity: "success",
          });
        } else {
          setNotification({
            open: true,
            message: `${uploadedIds.length} files uploaded successfully`,
            severity: "success",
          });
        }
      }
    } catch (error) {
      console.error("Unexpected error during upload:", error);
      setNotification({
        open: true,
        message: `Upload failed: ${
          error instanceof Error ? error.message : "Unknown error"
        }`,
        severity: "error",
      });
    }

    // Reset uploading state
    if (questionNumber === 1) {
      setIsUploading1(false);
    } else {
      setIsUploading2(false);
    }

    // Clear the file input
    event.target.value = "";
  };

  const handleDeleteAttachment = async (
    attachmentId: string,
    questionNumber: 1 | 2
  ) => {
    try {
      // Delete file from Supabase storage
      const { error } = await supabase.storage
        .from("dozen-attachments")
        .remove([`attachments/${attachmentId}`]);

      if (error) {
        console.error("Error deleting file:", error);
        return;
      }

      // Update the round state by removing the attachment ID
      if (questionNumber === 1) {
        const updatedAttachmentIds = (
          round.question_1.attachment_ids || []
        ).filter((id) => id !== attachmentId);

        setRound({
          ...round,
          question_1: {
            ...round.question_1,
            attachment_ids: updatedAttachmentIds,
          },
        });

        // Update attachments display list
        setAttachments1(attachments1.filter((a) => a.id !== attachmentId));
      } else {
        const updatedAttachmentIds = (
          round.question_2.attachment_ids || []
        ).filter((id) => id !== attachmentId);

        setRound({
          ...round,
          question_2: {
            ...round.question_2,
            attachment_ids: updatedAttachmentIds,
          },
        });

        // Update attachments display list
        setAttachments2(attachments2.filter((a) => a.id !== attachmentId));
      }
    } catch (error) {
      console.error("Unexpected error during deletion:", error);
    }
  };

  return {
    handleDeleteAttachment,
    setNotification,
    notification,
    loadAttachmentUrls,
    attachments1,
    attachments2,
    setAttachments1,
    setAttachments2,
    handleFileUpload,
    isUploading1,
    isUploading2,
  };
};

export default useRoundAttachments;
