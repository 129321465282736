import React from "react";
import TextField from "@mui/material/TextField";
import { SxProps } from "@mui/material";

interface NumberInputProps {
  value: number | null;
  onChange: (value: number | null) => void;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  sx?: SxProps
}

const NumberInput: React.FC<NumberInputProps> = ({
  value,
  onChange,
  label = "Number",
  placeholder = "Enter a number",
  disabled = false,
  sx,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const stringValue = event.target.value;

    // Convert to number and pass to parent
    if (stringValue === "") {
      // Handle empty input (convert to 0 or other default as needed)
      onChange(null);
    } else {
      const numValue = Number(stringValue);
      if (!isNaN(numValue)) {
        onChange(numValue);
      }
    }
  };

  return (
    <TextField
      label={label}
      type="number"
      value={value?.toString() ?? ""} // Convert number to string for input
      onChange={handleChange}
      placeholder={placeholder}
      disabled={disabled}
      sx={sx}
    />
  );
};

export default NumberInput;
