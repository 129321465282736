// src/App.jsx
import React, { useState } from "react";
import { useSupabase } from "./useSupabase";
import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  Paper,
  Stack,
  Divider,
  IconButton,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useTheme,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";

const Auth = () => {
  const { signIn, signUp, isConfirmEmail } = useSupabase();
  const theme = useTheme();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isSignIn, setIsSignIn] = useState<boolean>(true);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const location = useLocation();
  const locationFrom = location.state?.from || "/admin";
  const navigate = useNavigate();

  const onSignIn = async () => {
    try {
      await signIn(email, password);
      navigate(locationFrom);
    } catch (err) {
      // handle error
    }
  };

  const onSignUp = async () => {
    try {
      await signUp(email, password);
    } catch (err) {
      // handle error
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (isSignIn) {
      onSignIn();
    } else {
      onSignUp();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      if (isSignIn) {
        onSignIn();
      } else {
        onSignUp();
      }
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Container maxWidth="sm">
          <Paper
            elevation={3}
            sx={{
              p: { xs: 3, sm: 4 },
              borderRadius: 2,
              transition: "box-shadow 0.3s ease-in-out",
              "&:hover": {
                boxShadow: theme.shadows[6],
              },
            }}
          >
            <Typography
              variant="h4"
              align="center"
              gutterBottom
              fontWeight="bold"
              color="primary"
            >
              {isSignIn ? "Welcome Back" : "Create Account"}
            </Typography>

            <Typography
              variant="body1"
              align="center"
              sx={{ mb: 3, color: "text.secondary" }}
            >
              {isSignIn
                ? "Sign in to access your account"
                : "Join us today and get started"}
            </Typography>

            <Box component="form" onSubmit={handleSubmit}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={handleKeyDown}
                sx={{ mb: 2 }}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete={isSignIn ? "current-password" : "new-password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyDown}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ mb: 1 }}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1.5,
                }}
              >
                {isSignIn ? "Sign In" : "Create Account"}
              </Button>

              <Divider sx={{ my: 3 }}>
                <Typography variant="body2" color="text.secondary">
                  OR
                </Typography>
              </Divider>

              <Stack direction="row" justifyContent="center">
                <Typography variant="body1" color="text.secondary">
                  {isSignIn
                    ? "Don't have an account? "
                    : "Already have an account? "}
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={() => setIsSignIn(!isSignIn)}
                    sx={{
                      textTransform: "none",
                      fontWeight: 600,
                      p: 0,
                      minWidth: "auto",
                      ml: 0.5,
                      verticalAlign: "baseline",
                    }}
                  >
                    {isSignIn ? "Sign Up" : "Sign In"}
                  </Button>
                </Typography>
              </Stack>
            </Box>
          </Paper>
        </Container>
      </Box>

      {/* Confirm Email Dialog */}
      <Dialog
        open={isConfirmEmail}
        maxWidth="sm"
        PaperProps={{
          sx: {
            borderRadius: 2,
            p: 1,
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center", color: "primary.main" }}>
          Email Confirmation Required
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="center">
            A confirmation email has been sent to <strong>{email}</strong>.
            Please check your inbox and follow the instructions to complete your
            registration.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", pb: 2 }}>
          <Button variant="contained" color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default Auth;
