import { gameService } from "../data/gameService";
import { useParams } from "react-router-dom";
import { useSupabase } from "../auth/useSupabase";
import { App } from "../types/types";
import { Tables, TablesInsert } from "../../database.types";
import {
  boardAndGameConfigIsComplete,
  boardIsValid,
  getEmptyGameBoard,
} from "../utils/utils";
import { useState, useEffect, useCallback } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import GameEditor from "./GameEditor";
import { teamService } from "../data/teamService";
import { cloneDeep, debounce, set } from "lodash";
import GameBoardEditor from "./GameBoardEditor";
import { RetroContainer } from "../player/Player";

const GameBuilder = () => {
  const { gameId } = useParams<{ gameId: string }>();

  const { supabase, user } = useSupabase();

  const [loading, setLoading] = useState<boolean>(true);
  const [game, setGame] = useState<TablesInsert<"games"> | undefined>();
  const [board, setBoard] = useState<App.Board | null>(null);
  const [teams, setTeams] = useState<Tables<"teams">[]>([]);

  useEffect(() => {
    initializePage();
  }, [gameId]);

  useEffect(() => {
    if (board && boardIsValid(board)) {
      saveBoard();
    }
  }, [board]);

  useEffect(() => {
    if (game) {
      saveGame(game);
    }
  }, [game]);

  const initializePage = async () => {
    if (gameId) {
      try {
        setLoading(true);
        const gameRes = await gameService.getById(supabase, gameId);
        if (!gameRes) {
          throw new Error("Game not found");
        }
        const teamsRes = await teamService.getAllLeagueTeams(
          supabase,
          gameRes.league_id
        );
        const boardRes = await gameService.getGameBoard(
          supabase,
          gameRes.board_id
        );
        setTeams(teamsRes);
        setGame({
          ...gameRes,
          name: gameRes.name ?? undefined,
        });
        setBoard(
          boardRes?.board &&
            boardIsValid(boardRes?.board as unknown as App.Board)
            ? (boardRes.board as unknown as App.Board)
            : getEmptyGameBoard()
        );
      } catch (err) {
        // add error
      } finally {
        setLoading(false);
      }
    }
  };

  const saveBoard = async () => {
    if (board && game) {
      try {
        const configErrors = boardAndGameConfigIsComplete(board, game);
        console.log(configErrors);
        await gameService.upsertGameBoard(supabase, game.board_id, board);
        if (configErrors.length === 0) {
          setGame({ ...game, config_complete: true });
        } else if (game.config_complete) {
          setGame({ ...game, config_complete: false });
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  const saveGame = useCallback(
    debounce(async (newGame: TablesInsert<"games">) => {
      if (newGame.id) {
        try {
          await gameService.update(supabase, newGame.id, newGame);
        } catch (err) {
          console.error(err);
        }
      }
    }, 500),
    []
  );

  if (loading) {
    return (
      <Box
        height="100%"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!game) {
    return (
      <Box
        height="100vh"
        width="100vw"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography>Game not found</Typography>
      </Box>
    );
  }

  if (!user || !game.creator_id || user.id !== game.creator_id) {
    return (
      <Box
        height="100vh"
        width="100vw"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography>Unauthorized</Typography>
      </Box>
    );
  }

  return (
    <Box
      height="calc(100vh - 32px)"
      width="calc(100% - 32px)"
      overflow="hidden"
      p="16px"
      bgcolor="text.primary"
      display="flex"
      flexDirection="column"
      gap="8px"
    >
      {game && (
        <GameEditor
          onChangeGame={(g) =>
            setGame({
              ...g,
              config_complete:
                boardAndGameConfigIsComplete(board ?? undefined, g).length ===
                0,
            })
          }
          game={game}
          allTeams={teams}
        />
      )}

      {board && (
        <GameBoardEditor
          initialBoard={board}
          onBoardChange={(b, team1Niche, team2Niche) => {
            setBoard(b);
            if (game) {
              const errs = boardAndGameConfigIsComplete(b, game);
              let newGame = cloneDeep(game);
              if (errs.length === 0 && game?.board_id) {
                set(newGame, "config_complete", true);
              }
              if (team1Niche || team2Niche) {
                set(newGame, "team_1_niche_category", team1Niche);
                set(newGame, "team_2_niche_category", team2Niche);
              }
              setGame(newGame);
            }
          }}
          team1NicheCategory={game?.team_1_niche_category ?? undefined}
          team2NicheCategory={game?.team_2_niche_category ?? undefined}
          disabled={
            !!(!game || game.is_started || game.creator_id !== user?.id)
          }
        />
      )}
    </Box>
  );
};

export default GameBuilder;
