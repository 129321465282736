import { Box, Typography } from "@mui/material";
import { App } from "../types/types";
import { useEffect, useState } from "react";
import ClassicBonusRoundGrid from "./ClassicBonusRoundGrid";

interface ClassicBonusRoundPlayerProps {
  bonusRound: App.ClassicBonusRoundBoard;
}

const ClassicBonusRoundPlayer = (props: ClassicBonusRoundPlayerProps) => {
  const { bonusRound } = props;

  return (
    <Box display="flex" alignItems="center">
      <Box display="flex" flexDirection="column" gap="8px" alignItems="center">
        {bonusRound.team1Answers.map((a) => (
          <Box
            height="20px"
            width="20px"
            borderRadius="50%"
            bgcolor={a ? "green" : "red"}
          />
        ))}
      </Box>
      {/* TODO - Put Question Here */}
      <Box width="100%" display="flex" flexDirection="column" gap="12px">
        <Typography variant="h3">{bonusRound.question}</Typography>
        <ClassicBonusRoundGrid items={bonusRound.answers} />
      </Box>
      <Box display="flex" flexDirection="column" gap="8px">
        {bonusRound.team2Answers.map((a) => (
          <Box
            height="20px"
            width="20px"
            borderRadius="50%"
            bgcolor={a ? "green" : "red"}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ClassicBonusRoundPlayer;
